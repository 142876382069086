import React from "react";
import {
    Box,
    Button,
    Checkbox,
    Chip,
    CircularProgress,
    Container, DialogContentText,
    Fab,
    Fade,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    IconButton,
    InputLabel,
    LinearProgress,
    Menu,
    MenuItem,
    Select,
    Slide,
    Step,
    StepLabel,
    Stepper,
    Switch,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tabs,
    TextField,
    Tooltip,
    Typography,
    Zoom
} from "@mui/material";
import {Column} from "@material-table/core";
import AddIcon from "@mui/icons-material/Add";
import {VariantType, WithSnackbarProps} from "notistack";
import IDMSNodeManagingSession, {DMSNodeManagingSessionState} from "../models/IDMSNodeManagingSession";
import ModalDialog from "./ModalDialog";
import {appTheme} from "../pages/App/Styles";
import SignalCellularAltTwoToneIcon from "@mui/icons-material/SignalCellularAltTwoTone";
import SignalCellularConnectedNoInternet0BarTwoToneIcon
    from "@mui/icons-material/SignalCellularConnectedNoInternet0BarTwoTone";
import LocalStorageHelper from "../helpers/LocalStorageHelper";
import EndpointHelper from "../helpers/EndpointHelper";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import OfflineBolt from "@mui/icons-material/OfflineBolt";
import {
    DMSMessageFactory,
    DMSMethod,
    DMSNodeState,
    DMSRESTApiClient,
    DMSUpdateStrategy,
    DMSWSClient,
    IBBCBridgeAction,
    IBBCBridgeMessage, IBitbucketConfig,
    IDBNode,
    IDBUser,
    IDBUserGroup,
    IDMSClientConfig,
    IDMSExecuteBridgeAction,
    IDMSExecuteBridgeActionResult,
    IDMSFile,
    IDMSGetSoftwareLogsMessage,
    IDMSHealthCheckResult,
    IDMSListBridgeActions,
    IDMSListBridgeActionsResult,
    IDMSLogFile,
    IDMSNode,
    IDMSNodeBanState,
    IDMSRestartSoftwareMessage,
    IDMSResult,
    IDMSSettings,
    IDMSSoftwareBundle,
    IDMSUpdateConfigMessage,
    IDMSUpdateSoftwareMessage,
    IPInfo,
    IPortForwardingRule,
    Util,
    ValidationHelper
} from "dms_commons";
import PlayCircleFilledWhiteIcon from "@mui/icons-material/PlayCircleFilledWhite";
import HelpIcon from "@mui/icons-material/Help";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import DMSFilesTable from "./DMSFilesTable";
import INewNodeRequest from "../models/INewNodeRequest";
import INodeInfoDialogState from "../models/INodeInfoDialogState";
import IScreenGrabSession from "../models/IScreenGrabSession";
import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import StarIcon from '@mui/icons-material/Star';
import {LazyLog} from 'react-lazylog';
import RefreshIcon from "@mui/icons-material/Refresh";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import fileDownload from 'js-file-download';
import DMSUserActivityView from "./DMSUserActivityView";
import DMSUserView from "./DMSUserView";
import VpnLockIcon from '@mui/icons-material/VpnLock';
import DMSPersistentTable from "./DMSPersistentTable";
import Constants from "../Constants";
import DMSNodeHealthView from "./DMSNodeHealthView";
import ReactJson from "react-json-view";
import {
    Apple,
    Backpack,
    Beenhere,
    CameraAlt,
    DesktopWindows,
    LocationOn,
    MonitorHeart,
    Public,
    QuestionMark,
    Sell,
    WindowSharp
} from "@mui/icons-material";
import ReactDiffViewer from 'react-diff-viewer-continued';
import {TwoFactorRequest} from "../pages/App/App";
import DMSPortForwardingRulesView from "./DMSPortForwardingRulesView";
import CloseIcon from "@mui/icons-material/Close";
import INewApiClientRequest from "../models/INewApiClientRequest";
import {Autocomplete} from "@mui/lab";
import DMSDataRenderer from "./DMSDataRenderer";

var Buffer = require('buffer/').Buffer;  // note: the trailing slash is important!

interface IProps extends WithSnackbarProps {
    mode: "nodes" | "api_clients";
    targetNodeInfoModeConfig?: {
        targetNode: IDBNode,
        onCancel: () => void
    };
    twoFactorHandler: (request: TwoFactorRequest) => void;
    cancelTwoFactorRequest: () => void;
    classes: any;
    globalSettings?: IDMSSettings;
    dmsNodes: Map<string, IDBNode>;
    isLoadingNodes?: boolean;
    dmsFiles: IDMSFile[];
    isLoadingFiles: boolean;
    onLoadDataRequested: () => void;
    renderFilesTable: (fullTable: boolean, files?: IDMSFile[], isLoading?: boolean, onRowSelected?: (f: IDMSFile, index: number) => void) => void;
    dmsOnlineNodes: Map<string, IDMSNode>;
    bannedNodes: IDMSNodeBanState[];
    currentUser?: IDBUser;
    dmsClient: DMSWSClient;
    dmsRestClient: DMSRESTApiClient;
    logLine: (line: string) => void;
    definedSoftware: IDMSSoftwareBundle[];
    portRules: IPortForwardingRule[];
    onLoadPortRulesRequested: () => void;
    isLoadingPortRules: boolean;
    tableKey: string;
    dmsUserGroups?: IDBUserGroup[];
}

interface IState {
    selectedNodeInfoTab: number;
    dmsNodesTableColumns: Column<IDBNode>[];
    isDeletingNodeInProgress: boolean;
    isGettingNodeConfigInProgress: boolean;
    deletionTargetNode?: IDBNode;
    getConfigTargetNode?: IDBNode;
    nodeConfig?: IDMSClientConfig;
    deletionTargetNodeNameConfirmation?: string;
    screenGrabSession?: IScreenGrabSession;
    newNodeRequest?: INewNodeRequest;
    newApiClientRequest?: INewApiClientRequest;
    nodeManagingSession?: IDMSNodeManagingSession;
    isCreatingNodeInProgress: boolean;
    isCreatingApiClientInProgress: boolean;
    isDeletingApiClientInProgress: boolean;
    nodeInfoDialogState?: INodeInfoDialogState;
    nodeOptionsMenu?: any;
    canBatchUpdate: boolean;
    selectionMode: boolean;
    nodes: IDBNode[];
    generatedJwtToken?: string;
    deletionTargetApiClient?: IDBNode;
}

export default class DMSNodesView extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            nodeInfoDialogState: props.targetNodeInfoModeConfig?.targetNode ? {
                targetNode: props.targetNodeInfoModeConfig.targetNode,
                isLoadingSoftware: props.targetNodeInfoModeConfig ? true : undefined
            } : undefined,
            isDeletingNodeInProgress: false,
            isGettingNodeConfigInProgress: false,
            isCreatingNodeInProgress: false,
            isCreatingApiClientInProgress: false,
            isDeletingApiClientInProgress: false,
            selectedNodeInfoTab: 0,
            dmsNodesTableColumns: [],
            nodes: [],
            canBatchUpdate: false,
            selectionMode: false
        } as IState;

        if (props.targetNodeInfoModeConfig?.targetNode) {
            this.onShowNodeInfoRequested(props.targetNodeInfoModeConfig.targetNode);
        }
    }

    public componentDidMount() {
        this.initDmsNodeColumns();
    }

    public componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        if (this.props.dmsNodes === prevProps.dmsNodes && this.props.dmsOnlineNodes === prevProps.dmsOnlineNodes) {
            return;
        }

        this.initDmsNodeColumns();
    }

    private getOSIcon = (os: string) => {
        if (os.toLowerCase().indexOf("windows") > -1) {
            return <WindowSharp/>;
        } else if (os.toLowerCase().indexOf("darwin") > -1) {
            return <Apple/>;
        } else {
            return <DesktopWindows/>;
        }
    };

    private getSoftwareicon = (os: string) => {
        if (os.toLowerCase().indexOf("tagomat_v2") > -1) {
            return <Beenhere/>;
        } else if (os.toLowerCase().indexOf("tagomat") > -1) {
            return <Sell/>;
        } else if (os.toLowerCase().indexOf("bagomat") > -1) {
            return <Backpack/>;
        } else if ("dms_client") {
            return <Public/>;
        }
    };

    private initDmsNodeColumns = () => {
        //parent's dmsNodes changed
        const {classes, dmsNodes, dmsOnlineNodes, mode, tableKey} = this.props;

        let uniqueNodeLocations = new Array<string>();
        const uniqueClientNames = new Array<string>();

        let nodes = new Array<IDBNode>();

        for (const key in dmsNodes) {
            const n = dmsNodes[key];

            nodes.push(n);

            if (uniqueNodeLocations.indexOf(n.location) === -1) {
                uniqueNodeLocations.push(n.location);
            }

            let nodeVersionString: string | undefined;

            if (n.clientVersion) {
                nodeVersionString = n.clientVersion.major + "." + n.clientVersion.minor + "." + n.clientVersion.build;
            }

            const clientName = `${n.clientName} ${nodeVersionString ? `(${nodeVersionString})` : "No Client"}`;

            if (uniqueClientNames.indexOf(clientName) === -1) {
                uniqueClientNames.push(clientName);
            }
        }

        uniqueNodeLocations = uniqueNodeLocations.sort((a, b) => {
            return a.localeCompare(b);
        });

        const locationFilter = {};
        uniqueNodeLocations.forEach(n => {
            locationFilter[n] = n;
        });

        const clientFilter = {};
        uniqueClientNames.forEach(n => {
            clientFilter[n] = n;
        });

        clientFilter["Outdated"] = "Outdated";

        const softwareFilter = {};

        this.props.definedSoftware.forEach(s => {
            softwareFilter[s.name] = s.name;
        });

        let locationColumn;

        const preferredVersion = this.props.globalSettings?.preferredClientVersions ? this.props.globalSettings?.preferredClientVersions["dms_client"] : undefined;

        switch (mode) {
            case "nodes":
                locationColumn = {
                    title: "Location",
                    field: "location",
                    defaultFilter: DMSPersistentTable.getDefaultFilter(tableKey, "location"),
                    filtering: true,
                    lookup: locationFilter,
                    filterCellStyle: {
                        maxWidth: "120px"
                    },
                    render: (rowData) => {
                        return <Chip size={"small"} icon={<LocationOn/>} label={rowData.location}/>;
                    }
                };
                break;
            case "api_clients":
                locationColumn = {
                    title: "Membership",
                    field: "location",
                    defaultFilter: DMSPersistentTable.getDefaultFilter(tableKey, "membership"),
                    filtering: true,
                    lookup: locationFilter,
                    filterCellStyle: {
                        maxWidth: "120px"
                    },
                    render: (rowData) => {
                        return <Chip size={"small"} icon={<LocationOn/>} label={rowData.location}/>;
                    }
                };
                break;
        }

        let dmsNodesTableColumns: Column<IDBNode>[] = [
            {
                title: "Online",
                type: "boolean",
                width: "25px",
                align: "center",
                customSort: (a, b) => {
                    const isNodeAOnline = dmsOnlineNodes && dmsOnlineNodes[a.uid] !== undefined;
                    const isNodeBOnline = dmsOnlineNodes && dmsOnlineNodes[b.uid] !== undefined;

                    return (isNodeAOnline === isNodeBOnline) ? 0 : isNodeAOnline ? -1 : 1;
                },
                render: this.renderOnlineColumn
            },
            mode === "api_clients" ? {
                title: "Health",
                type: "boolean",
                width: "25px",
                align: "left",
                customSort: (a, b) => {
                    const isNodeAOnline = dmsOnlineNodes && dmsOnlineNodes[a.uid] !== undefined;
                    const isNodeBOnline = dmsOnlineNodes && dmsOnlineNodes[b.uid] !== undefined;

                    return (isNodeAOnline === isNodeBOnline) ? 0 : isNodeAOnline ? -1 : 1;
                },
                render: rowData => {
                    return <IconButton onClick={(e) => {
                        e.preventDefault();

                        this.setState({
                            nodeManagingSession: {
                                state: DMSNodeManagingSessionState.EXECUTING_NODE_BRIDGE_ACTION,
                                sessionMode: "actions",
                                targetNode: rowData
                            }
                        }, () => {
                            this.executeBridgeAction({
                                Id: "EXECUTE_HEALTH_CHECK",
                                description: "",
                                parameters: [],
                                bridgeType: "tagomat"
                            });
                        });
                    }}>
                        <MonitorHeart/>
                    </IconButton>;
                }
            } : undefined,
            {
                title: "Id",
                field: "uid",
                align: mode === "nodes" ? "center" : "left",
                filtering: true,
                hideFilterIcon: true,
                grouping: false,
                render: rowData => {
                    if (mode === "api_clients") {
                        return <DMSUserView isUserOnline={username => {
                            return dmsOnlineNodes[username] !== undefined;
                        }} classes={classes} username={rowData.uid}/>;
                    }

                    return <Chip size={"small"} variant={"filled"} label={rowData.uid}/>;
                }
            },
            locationColumn,
            mode === "nodes" ? {
                title: "Client State",
                sorting: false,
                grouping: false,
                align: "center",
                render: this.renderClientStateColumn
            } : undefined,
            mode === "nodes" ? {
                title: "OS",
                sorting: false,
                grouping: false,
                align: "center",
                render: (rowData) => {
                    return <Tooltip title={rowData.operatingSystem}>
                        <Chip
                            size={"small"}
                            icon={this.getOSIcon(rowData.operatingSystem)}
                            label={rowData.cpuArch ?? ""}/>
                    </Tooltip>;
                }
            } : undefined,
            {
                title: "Last Connection",
                type: "date",
                field: "lastConnection",
                filtering: false,
                grouping: false,
                align: "center",
                customSort: (a, b) => {
                    if (a.lastConnection && b.lastConnection) {
                        return (a.lastConnection as any)._seconds - (b.lastConnection as any)._seconds;
                    } else {
                        return 1;
                    }
                },
                render: (rowData) => {
                    const nodeCurrentInstance = dmsNodes[rowData.uid];
                    return (
                        <div>{nodeCurrentInstance?.lastConnection ? Util.relativeDateTimeStringFromDBTimestamp(nodeCurrentInstance?.lastConnection) : "N/A"}</div>);
                }
            },
            mode === "nodes" ? {
                title: "Software",
                align: "center",
                lookup: softwareFilter,
                defaultFilter: DMSPersistentTable.getDefaultFilter(tableKey, "software"),
                field: "software",
                filtering: true,
                customFilterAndSearch: (filter: Array<any>, rowData) => {
                    if (filter.length > 0) {
                        let include = false;
                        for (let i = 0; i < filter.length; i++) {
                            const f = filter[i];
                            include = (rowData.installedSoftware ?? []).findIndex(s => s.name === f) > -1;

                            if (include) {
                                break;
                            }
                        }

                        return include;
                    }
                    return true;
                },
                customSort: (a, b) => {
                    return Util.compareVersions(a.clientVersion, b.clientVersion);
                },
                render: this.renderSoftwareColumn
            } : undefined,
            {
                title: mode === "api_clients" ? "API Client Type" : "Client",
                align: "center",
                field: "clientVersion",
                filtering: true,
                defaultFilter: DMSPersistentTable.getDefaultFilter(tableKey, "clientVersion"),
                lookup: clientFilter,
                grouping: false,
                customFilterAndSearch: (filter, rowData) => {
                    if (filter == "Outdated") {

                        return Util.compareVersions(rowData.clientVersion, preferredVersion) == -1;
                    }

                    if (filter.length > 0 && rowData.clientVersion) {
                        let nodeVersionString = rowData.clientVersion.major + "." + rowData.clientVersion.minor + "." + rowData.clientVersion.build;
                        return filter.toString().startsWith(`${rowData.clientName} ${nodeVersionString ? `(${nodeVersionString})` : ""}`);
                    } else if (!rowData.clientVersion && filter.length === 1 && filter[0].indexOf("No Client") > -1) {
                        return true;
                    } else if (filter.length === 0) {
                        return true;
                    }

                    return false;
                },
                customSort: (a, b) => {
                    return Util.compareVersions(a?.clientVersion, b?.clientVersion);
                },
                render: this.renderClientColumn
            },
            {
                title: "Options",
                align: "center",
                sorting: false,
                render: rowData => {
                    return (
                        <IconButton
                            disabled={this.state.selectionMode}
                            onClick={(event) => {
                                event.stopPropagation();
                                const nodeOptionsMenu = this.handleOptionsClick(rowData, event);
                                this.setState({nodeOptionsMenu});
                            }}>
                            <MoreHorizIcon/>
                        </IconButton>
                    );
                }
            }
        ];

        dmsNodesTableColumns = dmsNodesTableColumns.filter(c => c !== undefined);

        dmsNodesTableColumns.forEach(c => {
            c.width = 1;
        });

        this.setState({
            dmsNodesTableColumns,
            nodes
        });
    };

    public render() {
        if (this.props.targetNodeInfoModeConfig && this.state.nodeInfoDialogState?.targetNode) {
            // when a targetNodeModeConfig has been set, we only want to display the node info dialog

            return <div>
                {
                    this.renderScreenGrabDialog()
                }
                {
                    this.renderNodeInfoDialog()
                }
            </div>;
        }

        const {classes, tableKey, mode} = this.props;
        const {dmsNodes, isLoadingNodes} = this.props;
        const {nodes, dmsNodesTableColumns, nodeOptionsMenu} = this.state;

        let tableTitle;

        switch (mode) {
            case "nodes":
                tableTitle = "Nodes";
                break;
            case "api_clients":
                tableTitle = "API Clients";
                break;
        }

        return <Grid item xs>
            <TableContainer>
                {this.renderManageNodeDialog()}
                {nodeOptionsMenu}
                <DMSPersistentTable
                    key={tableKey}
                    tableKey={tableKey}
                    isLoading={isLoadingNodes}
                    title={tableTitle}
                    columns={dmsNodesTableColumns}
                    data={nodes}
                    onSelectionChange={(data) => {
                        this.onRowSelectionChanged(data);
                    }}
                    options={{
                        headerStyle: {
                            backgroundColor: "rgb(65,65,65)",
                        },
                        searchText: DMSPersistentTable.getLastSearchTerm(tableKey) ?? undefined,
                        selection: true,
                        showTitle: true,
                        draggable: true,
                        pageSize: 10,
                        pageSizeOptions: [10, 25, 50, 100],
                        loadingType: "overlay",
                        filtering: true,
                        grouping: true,
                        emptyRowsWhenPaging: false,
                        padding: "dense",
                        idSynonym: "uid"
                    }}
                    actions={[
                        {
                            icon: "cached",
                            disabled: !this.state.canBatchUpdate,
                            tooltip: "Batch Restart Software",
                            onClick: (e, data) => {
                                this.setState({nodeOptionsMenu: undefined});
                                this.onManageNodesRequested(data as IDBNode[], "batch-restart");
                            },
                        },
                        {
                            icon: "settings_application",
                            disabled: !this.state.canBatchUpdate,
                            tooltip: "Batch Update Config",
                            onClick: (e, data) => {
                                this.setState({nodeOptionsMenu: undefined});
                                this.onManageNodesRequested(data as IDBNode[], "batch-config");
                            },
                        },
                        {
                            icon: "system_update",
                            disabled: !this.state.canBatchUpdate,
                            tooltip: "Batch Update Software",
                            onClick: (e, data) => {
                                this.setState({nodeOptionsMenu: undefined});
                                this.onManageNodesRequested(data as IDBNode[], "batch-software");
                            },
                        },
                        {
                            icon: 'refresh',
                            tooltip: 'Refresh Data',
                            isFreeAction: true,
                            onClick: this.props.onLoadDataRequested
                        },
                    ]}
                    onRowClick={(e, rowData) => {
                        if (e?.defaultPrevented) {
                            return;
                        }

                        const nodeCurrentInstance = dmsNodes[rowData?.uid ?? ""];
                        if (nodeCurrentInstance) {
                            this.onShowNodeInfoRequested(nodeCurrentInstance);
                        }
                    }}
                />
                <Grid>
                    <Fab
                        onClick={() => {
                            switch (mode) {
                                case "nodes":
                                    this.setState({newNodeRequest: {}});
                                    break;
                                case "api_clients":
                                    this.setState({newApiClientRequest: {}});
                                    break;
                            }
                        }} className={classes.fab} color="primary" aria-label="add">
                        <AddIcon/>
                    </Fab>
                    {this.renderNewNodeDialog()}
                    {this.renderNewApiClientDialog()}
                    {this.renderDeleteNodeDialog()}
                    {this.renderGetNodeConfigDialog()}
                    {this.renderDeleteApiClientDialog()}
                    {this.renderNodeInfoDialog()}
                    {this.renderScreenGrabDialog()}
                    {this.renderGeneratedJwtTokenDialog()}
                </Grid>
            </TableContainer>
        </Grid>;
    };

    private onRowSelectionChanged = (rows: IDBNode[]) => {
        let selectionMode = (rows && rows.length > 1);

        let canBatchUpdate = selectionMode && rows.length < 400;

        this.setState({canBatchUpdate, selectionMode});
    };

    private handleOptionsClick = (rowData, event) => {
        const {dmsNodes, dmsOnlineNodes, mode} = this.props;

        const nodeCurrentInstance = dmsNodes[rowData.uid];

        if (!nodeCurrentInstance) {
            return;
        }

        const isNodeOnline = dmsOnlineNodes && dmsOnlineNodes[nodeCurrentInstance.uid] !== undefined;
        const isNodeAWebBrowser = nodeCurrentInstance?.operatingSystem === "browser";

        const nodeSupportsScreenGrab = isNodeOnline && Util.compareVersions(nodeCurrentInstance.clientVersion!, {
            major: 1,
            minor: 0,
            build: 16
        }) > 0;

        const nodeSupportsGettingLogs = isNodeOnline && !isNodeAWebBrowser && Util.compareVersions(nodeCurrentInstance.clientVersion!, {
            major: 1,
            minor: 0,
            build: 30
        }) > 0;

        const nodeOptionsAnchor = event.currentTarget;

        return (<Menu
            open={Boolean(nodeOptionsAnchor)}
            onClose={() => {
                this.setState({nodeOptionsMenu: undefined});
            }}
            id="menu-cell"
            anchorEl={nodeOptionsAnchor}
            anchorOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
        >
            <MenuItem disabled={true}>{nodeCurrentInstance!.uid}</MenuItem>
            {!isNodeOnline || isNodeAWebBrowser || mode !== "nodes" ? undefined : <MenuItem
                disabled={!isNodeOnline || isNodeAWebBrowser}
                onClick={() => {
                    this.setState({nodeOptionsMenu: undefined});
                    this.onManageNodeRequested(nodeCurrentInstance, "software");
                }}>Update Software</MenuItem>}
            {!isNodeOnline || isNodeAWebBrowser || mode !== "nodes" ? undefined :
                <MenuItem
                    disabled={!isNodeOnline || isNodeAWebBrowser}
                    onClick={() => {
                        this.setState({nodeOptionsMenu: undefined});
                        this.onManageNodeRequested(nodeCurrentInstance, "config");
                    }}>Update Configuration</MenuItem>
            }
            {
                (this.props.currentUser?.memberOf?.indexOf("root") ?? -1) > -1 ? <MenuItem
                    onClick={() => {
                        this.setState({
                            nodeOptionsMenu: undefined
                        });
                        this.onGetNodeConfigRequested(nodeCurrentInstance);
                    }}>{"View Node Config"}
                </MenuItem> : undefined
            }
            {!isNodeOnline || isNodeAWebBrowser ? undefined :
                <MenuItem
                    disabled={!isNodeOnline || isNodeAWebBrowser}
                    onClick={() => {
                        this.setState({nodeOptionsMenu: undefined});
                        this.onManageNodeRequested(nodeCurrentInstance, "restart");
                    }}>Restart Software</MenuItem>
            }
            {!isNodeOnline || isNodeAWebBrowser ? undefined :
                <MenuItem
                    disabled={!isNodeOnline || isNodeAWebBrowser}
                    onClick={() => {
                        this.setState({nodeOptionsMenu: undefined});
                        this.onManageNodeRequested(nodeCurrentInstance, "actions");
                    }}>Actions</MenuItem>
            }
            {!isNodeOnline || isNodeAWebBrowser ? undefined :
                <MenuItem
                    disabled={!isNodeOnline || isNodeAWebBrowser}
                    onClick={() => {
                        this.setState({nodeOptionsMenu: undefined});
                        this.onDisconnectNodeRequested(nodeCurrentInstance);
                    }}>Disconnect Node</MenuItem>
            }
            {!nodeSupportsGettingLogs ? undefined :
                <MenuItem disabled={!nodeSupportsScreenGrab}
                          onClick={() => {
                              this.setState({nodeOptionsMenu: undefined});
                              this.onManageNodeRequested(nodeCurrentInstance, "logs");
                          }}>
                    View Logs
                </MenuItem>}
            {mode !== "nodes" || !nodeSupportsScreenGrab ? undefined :
                <MenuItem disabled={!nodeSupportsScreenGrab}
                          onClick={() => {
                              this.setState({nodeOptionsMenu: undefined});
                              this.captureScreenshot({targetNode: nodeCurrentInstance});
                          }}>
                    Capture Screen
                </MenuItem>
            }
            <MenuItem
                onClick={() => {
                    this.setState({nodeOptionsMenu: undefined});
                    this.onShowNodeInfoRequested(nodeCurrentInstance);
                }}>{"Info"}
            </MenuItem>
            <MenuItem
                onClick={() => {
                    switch (mode) {
                        case "nodes":
                            this.setState({
                                nodeOptionsMenu: undefined,
                                deletionTargetNode: nodeCurrentInstance
                            });
                            break;
                        case "api_clients":
                            this.setState({
                                nodeOptionsMenu: undefined,
                                deletionTargetApiClient: nodeCurrentInstance
                            });
                            break;
                    }
                }}>{"Delete"}</MenuItem>
        </Menu>);
    };


    private renderUpdateSteps(installedSoftware: IDMSSoftwareBundle[], isBatchUpdate?: boolean) {
        const {classes} = this.props;
        const {nodeManagingSession} = this.state;
        const {dmsFiles, isLoadingFiles} = this.props;

        if (!nodeManagingSession) {
            return undefined;
        }

        switch (nodeManagingSession.state) {
            case DMSNodeManagingSessionState.BATCH_GETTING_LATEST_CONFIG_FROM_BITBUCKET:
            case DMSNodeManagingSessionState.UPDATING_CONFIG:
                return <CircularProgress color="inherit"/>;
            case DMSNodeManagingSessionState.GETTING_LATEST_CONFIG_FROM_BITBUCKET:
            case DMSNodeManagingSessionState.GETTING_NODE_SOFTWARE:
            case DMSNodeManagingSessionState.GOT_NODE_SOFTWARE:
            case DMSNodeManagingSessionState.NODE_RESTARTING_SOFTWARE: {
                if (isBatchUpdate) {
                    return <div style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center"
                    }}>
                        <div className={classes.chipBarContainer} style={{width: "100%"}}>
                            <LinearProgress style={{width: "100%", marginBottom: 16}}
                                            value={nodeManagingSession.batchUpdateProgress ?? 0}
                                            variant={"determinate"}/>
                            {Object.keys(nodeManagingSession.batchUpdateContext ?? {}).map((key, index) => {
                                const batchSoftwareQueryResult = nodeManagingSession?.batchUpdateContext![key];

                                const targetChipColor = batchSoftwareQueryResult && batchSoftwareQueryResult.software && batchSoftwareQueryResult.software.length > 0 ? "primary" : "default";

                                const nodeIsOffline = batchSoftwareQueryResult.offline;

                                const isLoadingNodeSoftware = !nodeIsOffline && !batchSoftwareQueryResult.software;

                                let targetChipIcon: any;

                                if (isLoadingNodeSoftware) {
                                    targetChipIcon =
                                        <CircularProgress style={{color: "lightgrey"}} size={18}
                                                          variant={"indeterminate"}/>;
                                } else {
                                    targetChipIcon = nodeIsOffline ?
                                        <OfflineBolt/> : batchSoftwareQueryResult && (batchSoftwareQueryResult.software ?? []).length > 0 ?
                                            <DoneIcon/> : <ClearIcon/>;
                                }

                                return (
                                    <Chip
                                        key={key}
                                        variant={"outlined"}
                                        size="small"
                                        style={{
                                            color: nodeIsOffline ? "white" : undefined,
                                            backgroundColor: nodeIsOffline ? "rgba(119, 97, 64, 0.85)" : undefined
                                        }}
                                        color={targetChipColor}
                                        icon={targetChipIcon}
                                        label={key}
                                    />
                                );
                            })}
                        </div>
                        {nodeManagingSession.sessionMode === "batch-software" || nodeManagingSession.sessionMode === "batch-config" ?
                            < div style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center"
                            }}>
                                <FormControlLabel
                                    key={"update_seq_checkbox"}
                                    control={
                                        <Switch
                                            onChange={(e) => {
                                                const checked = e.target?.checked ?? false;
                                                this.setState(prevState => ({
                                                    nodeManagingSession: {
                                                        ...prevState.nodeManagingSession!,
                                                        batchUpdateSequentially: checked
                                                    }
                                                }));
                                            }
                                            }
                                            checked={this.state.nodeManagingSession?.batchUpdateSequentially}
                                            color="primary"
                                        />
                                    }
                                    label="Update Sequentially"
                                />
                                <Fade unmountOnExit in={nodeManagingSession.batchUpdateSequentially}>
                                    <DialogContentText>The update process updates nodes one at a time and aborts if any
                                        node fails.</DialogContentText>
                                </Fade>
                            </div> : undefined}
                    </div>;
                } else {
                    return <CircularProgress color="inherit"/>;
                }
            }
            case DMSNodeManagingSessionState.SELECTING_SOFTWARE_TO_UPDATE:
                const softwareTable = this.renderSoftwareTable(installedSoftware, (s) => {
                    return !s.manifest && (nodeManagingSession?.sessionMode === "restart" || nodeManagingSession?.sessionMode === "config");
                }, (s, index) => {
                    this.setState(prevState => ({
                        nodeManagingSession: {
                            ...prevState.nodeManagingSession!,
                            selectedSoftware: installedSoftware[index]
                        }
                    }), () => {
                        switch (nodeManagingSession?.sessionMode) {
                            case "batch-software":
                            case "software":
                                this.setState(prevState => ({
                                    nodeManagingSession: {
                                        ...prevState.nodeManagingSession!,
                                        state: DMSNodeManagingSessionState.SELECTING_UPDATE_PAYLOAD
                                    }
                                }));
                                break;
                            case "batch-config":
                                this.getConfigsForLocationFromBitbucket(this.state.nodeManagingSession!);
                                break;
                            case "config":
                                if (this.state.nodeManagingSession?.selectedSoftware?.name === "dms_client") {
                                    if (Util.compareVersions(nodeManagingSession.targetNode?.clientVersion, {
                                        major: 2,
                                        minor: 8,
                                        build: 5
                                    }) <= -1) {
                                        this.displaySnackbar("Updating the dms_client config requires version 2.8.5 or newer.", "error");
                                        return;
                                    }

                                    this.setState(prevState => ({
                                        nodeManagingSession: {
                                            ...this.state.nodeManagingSession!,
                                            state: DMSNodeManagingSessionState.COPY_CONFIG_FROM_NODE
                                        }
                                    }));
                                } else {
                                    this.getNodeConfigFromBitbucket(this.state.nodeManagingSession!);
                                }
                                break;
                            case "restart":
                                this.restartSoftwareOnNode(this.state.nodeManagingSession!);
                                break;
                            case "batch-restart":
                                this.restartSoftwareOnNodes(this.state.nodeManagingSession!);
                                break;
                            case "logs":
                                this.listNodeLogs(this.state.nodeManagingSession!);
                                break;
                            case "actions":
                                this.getNodeBridgeActions(this.state.nodeManagingSession!);
                                break;
                        }
                    });
                }, isBatchUpdate);
                return <div style={{flex: 1, width: "100%", height: "100%"}}>
                    {softwareTable}
                    {nodeManagingSession.sessionMode === "software" || nodeManagingSession.sessionMode === "batch-software" ?
                        < div style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center"
                        }}>
                            <FormControlLabel
                                key={"ignore_version_check_switch"}
                                control={
                                    <Switch
                                        onChange={(e) => {
                                            const checked = e.target?.checked ?? false;
                                            this.setState(prevState => ({
                                                nodeManagingSession: {
                                                    ...prevState.nodeManagingSession!,
                                                    ignoreSoftwareVersion: checked
                                                }
                                            }));
                                        }
                                        }
                                        checked={this.state.nodeManagingSession?.ignoreSoftwareVersion ?? false}
                                        color="primary"
                                    />
                                }
                                label="Ignore Version Check"
                            />
                            <Fade unmountOnExit in={nodeManagingSession.ignoreSoftwareVersion}>
                                <DialogContentText>The update process allows installing older
                                    versions.</DialogContentText>
                            </Fade>
                        </div> : undefined}
                </div>;
            case DMSNodeManagingSessionState.SELECTING_UPDATE_PAYLOAD:
                return <DMSFilesTable dmsFiles={dmsFiles}
                                      isLoading={isLoadingFiles}
                                      fullTable={false}
                                      onLoadDataRequested={this.props.onLoadDataRequested}
                                      onRowSelected={(f, i) => {
                                          if (isBatchUpdate) {
                                              this.updateSoftwareOnNodes(nodeManagingSession, f);
                                          } else {
                                              this.updateSoftwareOnNode(nodeManagingSession, f);
                                          }
                                      }
                                      }
                                      classes={classes}
                                      enqueueSnackbar={this.props.enqueueSnackbar}
                                      closeSnackbar={this.props.closeSnackbar}/>;
            case DMSNodeManagingSessionState.BATCH_GOT_CONFIG_FROM_BITBUCKET:
                return (<div style={{width: "100%"}}>
                    <p>
                        <b>Ready to update config on {nodeManagingSession.batchTargetNodes?.length} nodes, it is
                            recommended that you review the fetched config before continuing!</b>
                    </p>
                    <FormControl style={{minWidth: "120px"}}>
                        <InputLabel>Node</InputLabel>
                        <Select
                            value={nodeManagingSession.selectedBitbucketConfig}
                            onChange={(event) => {
                                if (event.target.value) {
                                    nodeManagingSession.selectedBitbucketConfig = nodeManagingSession?.batchConfigs?.find(f => f.node === event.target.value as string);
                                    this.forceUpdate();
                                }
                            }}
                        >
                            {
                                nodeManagingSession.batchTargetNodes!.map((n, key) => {
                                    return <MenuItem key={key} value={n.uid}>{n.uid}</MenuItem>;
                                })
                            }
                        </Select>
                    </FormControl>
                    <TextField value={nodeManagingSession!.selectedBitbucketConfig?.hash ?? ""}
                               className={classes.dialogTextField}
                               fullWidth
                               InputProps={{
                                   readOnly: true,
                               }}
                               label={"Commit Hash"}/>
                    <TextField
                        value={nodeManagingSession.selectedBitbucketConfig?.contents ?? ""}
                        className={classes.dialogTextField}
                        multiline
                        fullWidth
                        InputProps={{
                            readOnly: true,
                        }}
                        label={"Config"}/>
                </div>);
            case DMSNodeManagingSessionState.GOT_CONFIG_FROM_BITBUCKET:
                return (<div style={{width: "100%"}}>
                    {
                        (nodeManagingSession.selectedBitbucketConfig?.hash?.length ?? 0) > 0 ?
                            <TextField value={nodeManagingSession!.selectedBitbucketConfig!.hash}
                                       className={classes.dialogTextField}
                                       fullWidth
                                       InputProps={{
                                           readOnly: true,
                                       }}
                                       label={"Commit Hash"}/> : undefined
                    }
                    <TextField
                        value={nodeManagingSession.selectedBitbucketConfig!.author}
                        className={classes.dialogTextField}
                        fullWidth
                        InputProps={{
                            readOnly: true,
                        }}
                        label={"Author"}/>
                    {
                        nodeManagingSession.selectedBitbucketConfig?.commitDate ? <TextField
                            value={nodeManagingSession.selectedBitbucketConfig?.commitDate ? Util.relativeDateTimeString(nodeManagingSession.selectedBitbucketConfig!.commitDate, true) : ""}
                            className={classes.dialogTextField}
                            fullWidth
                            InputProps={{
                                readOnly: true,
                            }}
                            label={"Committed"}/> : undefined
                    }
                    {
                        nodeManagingSession.selectedBitbucketConfig?.comment ?
                            <TextField value={nodeManagingSession.selectedBitbucketConfig!.comment}
                                       className={classes.dialogTextField}
                                       fullWidth
                                       multiline
                                       InputProps={{
                                           readOnly: true,
                                       }}
                                       label={"Comment"}/> : undefined
                    }
                    <FormControl fullWidth className={classes.dialogTextField}>
                        <FormLabel>Config</FormLabel>
                        <ReactDiffViewer
                            useDarkTheme={true}
                            splitView={true}
                            styles={{
                                variables: {
                                    dark: {
                                        diffViewerBackground: "transparent",
                                        gutterBackgroundDark: "transparent",
                                        gutterBackground: "transparent",
                                        diffViewerTitleBackground: "transparent",
                                        diffViewerColor: "white",
                                        diffViewerTitleBorderColor: "transparent",
                                        diffViewerTitleColor: "white",

                                        addedGutterBackground: "transparent",
                                        emptyLineBackground: "transparent"
                                    }
                                }
                            }}
                            leftTitle={"Old"}
                            rightTitle={"New"}
                            oldValue={nodeManagingSession.oldConfig ?? ""}
                            newValue={nodeManagingSession.selectedBitbucketConfig!.contents}/>
                    </FormControl>
                </div>);
            case DMSNodeManagingSessionState.COPY_CONFIG_FROM_NODE:
                return (
                    <div style={{flex: 1, justifyContent: "center", alignItems: "center"}}>
                        <DialogContentText
                            style={{
                                textAlign: "center",
                                marginBottom: 16
                            }}>Please select which node you would like to copy the config from.</DialogContentText>
                        <Autocomplete
                            fullWidth
                            style={{margin: 8, minWidth: "180px"}}
                            getOptionLabel={(s) => {
                                return s.uid;
                            }}
                            renderInput={(params) => <TextField required {...params} label={"Source Node"}/>}
                            options={Object.values(this.props.dmsNodes).filter(n => n.uid !== nodeManagingSession.targetNode!.uid)}
                            onChange={(event, newValue) => {
                                this.setState(prevState => ({
                                    nodeManagingSession: {
                                        ...nodeManagingSession!,
                                        copyFromNode: newValue
                                    }
                                }));
                            }}
                        />
                        <Fade in={nodeManagingSession.copyFromNode?.uid !== undefined}>
                            <DialogContentText
                                style={{
                                    textAlign: "center",
                                    marginTop: 16
                                }}>{`⚠️ Keep in mind that the updated node ('${nodeManagingSession.targetNode!.uid}') will become '${nodeManagingSession.copyFromNode?.uid}' once the update completes.`}</DialogContentText>
                        </Fade>
                        <Fade
                            timeout={500}
                            in={nodeManagingSession.copyFromNode && this.props.dmsOnlineNodes[nodeManagingSession.copyFromNode.uid] !== undefined}>
                            <DialogContentText
                                style={{
                                    textAlign: "center",
                                    marginTop: 16
                                }}>{`⚠️ Node '${nodeManagingSession.copyFromNode?.uid}' is online, updating will cause both nodes to get stuck in a reconnection loop, please proceed with caution. ⚠️`}</DialogContentText>
                        </Fade>

                    </div>
                );
            case DMSNodeManagingSessionState.UPDATING_SOFTWARE: {
                const targetNode = this.props.dmsNodes[nodeManagingSession.targetNode?.uid ?? ""];

                if (targetNode && targetNode["updateProgress"]) {
                    return <LinearProgress
                        style={{width: "100%", marginBottom: 16}}
                        variant={"determinate"}
                        value={targetNode["updateProgress"]}
                    />;
                } else {
                    return <CircularProgress color={"inherit"}/>;
                }
            }
            case DMSNodeManagingSessionState.UPDATING_CONFIG_SEQUENTIALLY:
            case DMSNodeManagingSessionState.UPDATING_SOFTWARE_SEQUENTIALLY: {
                return <div style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center"
                }}>
                    <div className={classes.chipBarContainer} style={{width: "100%"}}>
                        <LinearProgress style={{width: "100%", marginBottom: 16}}
                                        value={nodeManagingSession.batchUpdateProgress ?? 0}
                                        variant={"determinate"}/>
                        {Object.keys(nodeManagingSession.batchUpdateContext ?? {}).map((key, index) => {
                            const batchSoftwareQueryResult = nodeManagingSession?.batchUpdateContext![key];

                            const targetChipColor = batchSoftwareQueryResult && batchSoftwareQueryResult.updated ? "primary" : "default";
                            let targetChipIcon: any;

                            const nodeIsOffline = batchSoftwareQueryResult.offline;

                            if (!batchSoftwareQueryResult.updated && !batchSoftwareQueryResult.offline) {
                                if (batchSoftwareQueryResult.updating) {
                                    targetChipIcon = <CircularProgress style={{color: "lightgrey"}} size={18}
                                                                       variant={"indeterminate"}/>;
                                } else {
                                    targetChipIcon = <ClearIcon/>;
                                }
                            } else {
                                targetChipIcon = batchSoftwareQueryResult.offline ? <OfflineBolt/> : <DoneIcon/>;
                            }

                            return (
                                <Chip
                                    key={key}
                                    variant={"outlined"}
                                    size="small"
                                    style={{
                                        color: nodeIsOffline ? "white" : undefined,
                                        backgroundColor: nodeIsOffline ? "rgba(119, 97, 64, 0.85)" : undefined
                                    }}
                                    color={targetChipColor}
                                    icon={targetChipIcon}
                                    label={key}
                                />
                            );
                        })}
                    </div>
                </div>;
            }
            case DMSNodeManagingSessionState.GETTING_NODE_LOG:
            case DMSNodeManagingSessionState.LISTING_NODE_LOGS:
            case DMSNodeManagingSessionState.LISTING_NODE_BRIDGE_ACTIONS:
            case DMSNodeManagingSessionState.EXECUTING_NODE_BRIDGE_ACTION:
                return <CircularProgress color="inherit"/>;
            case DMSNodeManagingSessionState.LISTED_NODE_LOGS:
                return <TableContainer>
                    <Table size={"small"} className={classes.table}>
                        <TableHead>
                            <TableRow>
                                <TableCell align="left">Filename</TableCell>
                                <TableCell align="left">Size</TableCell>
                                <TableCell align="right">Modified</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {nodeManagingSession!.nodeLogsList!.map((row, index) => {
                                return (
                                    <TableRow onClick={() => {
                                        this.getNodeLog(nodeManagingSession!, row.name);
                                    }} hover={true} key={index.toString()}>
                                        <TableCell component="th" scope="row"
                                                   align="left">
                                            {row.name}
                                        </TableCell>
                                        <TableCell component="th" scope="row"
                                                   align="left">
                                            {Util.formatBytes(row.size ?? 0)}
                                        </TableCell>
                                        <TableCell component="th" scope="row"
                                                   align="right">
                                            {row.dateModified ? Util.relativeDateTimeString(row.dateModified, true) : "N/A"}
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>;
            case DMSNodeManagingSessionState.LISTED_NODE_BRIDGE_ACTIONS:
                return <TableContainer>
                    <Table size={"small"} className={classes.table}>
                        <TableHead>
                            <TableRow>
                                <TableCell align="left">Id</TableCell>
                                <TableCell align="left">Description</TableCell>
                                <TableCell align="right">Parameters</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {nodeManagingSession!.nodeBridgeActions!.map((row, index) => {
                                return (
                                    <TableRow onClick={() => {
                                        this.onBridgeActionSelected(row);
                                    }} hover={true} key={index.toString()}>
                                        <TableCell component="th" scope="row"
                                                   align="left">
                                            {row.Id}
                                        </TableCell>
                                        <TableCell component="th" scope="row"
                                                   align="left">
                                            {row.description}
                                        </TableCell>
                                        <TableCell component="th" scope="row"
                                                   align="right">
                                            {
                                                (row.parameters ?? []).map((p, i) => {
                                                    return < Chip key={i} style={{margin: 2}} size={"small"}
                                                                  label={`${p.type} ${p.name}`}/>;
                                                })
                                            }
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>;
            case DMSNodeManagingSessionState.CONFIGURE_NODE_BRIDGE_ACTION:
                return nodeManagingSession.selectedAction!.parameters!.map((p, key) => {
                    return (p.validValues?.length ?? 0) > 0 || p.type == "boolean"
                        ? p.type == "boolean"
                            ? <FormControl key={key} style={{minWidth: "120px"}} fullWidth={true}>
                            <FormControlLabel
                                key={p.name}
                                control={
                                    <Checkbox
                                        checked={nodeManagingSession.selectedAction!.payload![p.name] ?? false}
                                        onChange={(event) => {
                                            this.setState({
                                                nodeManagingSession: {
                                                    ...nodeManagingSession,
                                                    selectedAction: {
                                                        ...nodeManagingSession?.selectedAction!,
                                                        payload: {
                                                            ...nodeManagingSession?.selectedAction!.payload!,
                                                            [p.name]: event.target.checked
                                                        }
                                                    }
                                                }
                                            });
                                        }}
                                    />
                                }
                                label={p.name}
                            />
                            </FormControl>
                            :  <FormControl key={key} style={{minWidth: "120px"}} fullWidth={true}>
                            <InputLabel>{p.name}</InputLabel>
                            <Select
                                variant={"standard"}
                                value={nodeManagingSession.selectedAction!.payload![p.name] ?? ""}
                                onChange={(event) => {
                                    let newValue = "";
                                    if (event && event.target && event.target.value) {
                                        newValue = event.target.value as string;
                                    }

                                    if (newValue) {
                                        this.setState({
                                            nodeManagingSession: {
                                                ...nodeManagingSession,
                                                selectedAction: {
                                                    ...nodeManagingSession?.selectedAction!,
                                                    payload: {
                                                        ...nodeManagingSession?.selectedAction!.payload!,
                                                        [p.name]: newValue
                                                    }
                                                }
                                            }
                                        });
                                    }
                                }}
                            >
                                {
                                    (p.validValues ?? []).map((k, key) => {
                                        return <MenuItem key={key} value={k}>{`${k}`}</MenuItem>;
                                    })
                                }
                            </Select>
                        </FormControl>
                        : <TextField value={nodeManagingSession.selectedAction!.payload![p.name] ?? ""}
                                     disabled={this.state.isDeletingNodeInProgress}
                                     onChange={(event) => {
                                         this.setState({
                                             nodeManagingSession: {
                                                 ...nodeManagingSession,
                                                 selectedAction: {
                                                     ...nodeManagingSession?.selectedAction!,
                                                     payload: {
                                                         ...nodeManagingSession?.selectedAction!.payload!,
                                                         [p.name]: event.target.value
                                                     }
                                                 }
                                             }
                                         });
                                     }}
                                     className={classes.dialogTextField}
                                     fullWidth
                                     label={p.name}/>;
                });
            case DMSNodeManagingSessionState.GOT_NODE_LOG:
                return <div style={{backgroundColor: "#487840", height: "100%", width: "100%"}}>
                    <p style={{
                        position: "absolute",
                        marginLeft: "16px",
                        marginTop: "12px",
                        fontWeight: 600
                    }}>{nodeManagingSession.nodeLog!.name}</p>
                    <LazyLog
                        selectableLines
                        extraLines={0} height={"auto"} follow={true}
                        enableSearch={true}
                        caseInsensitive={true}
                        text={nodeManagingSession.nodeLog!.contents}/>
                </div>;
            case DMSNodeManagingSessionState.EXECUTED_NODE_BRIDGE_ACTION_WITH_RESULT:
                return <div style={{width: "100%"}}>
                    <div style={{display: "flex", justifyContent: "center", marginBottom: 4}}>
                        <Button variant={"contained"} title={"Re-run"} onClick={() => {
                            this.executeBridgeAction(nodeManagingSession.selectedAction!);
                        }}>Re-run</Button>
                    </div>
                    {
                        nodeManagingSession?.actionResult?.contentType === undefined ?
                            <ReactJson style={{width: "100%"}} theme={"monokai"}
                                       src={nodeManagingSession?.actionResult?.payload ?? {}}/> : undefined
                    }
                    {
                        nodeManagingSession?.actionResult?.contentType === "text" ?
                            <div style={{height: "500px"}}>
                                <LazyLog
                                    selectableLines
                                    caseInsensitive={true}
                                    extraLines={0}
                                    height={"auto"}
                                    follow={true}
                                    enableSearch={true}
                                    text={nodeManagingSession?.actionResult?.payload?.text}
                                />
                            </div> : undefined
                    }
                    {
                        nodeManagingSession?.actionResult?.contentType === "image/jpeg" ?
                            <img alt={"action result"} style={{maxWidth: "100%"}}
                                 src={nodeManagingSession?.actionResult?.payload.base64}/> : undefined
                    }
                </div>;
            default:
                return undefined;
        }
    };

    private renderManageNodeDialog = () => {
        const {classes} = this.props;
        const {nodeManagingSession} = this.state;

        const installedSoftware: IDMSSoftwareBundle[] = nodeManagingSession?.nodeSoftware ?? new Array<IDMSSoftwareBundle>();

        if (!nodeManagingSession) {
            return undefined;
        }

        const isBatchUpdate = nodeManagingSession && nodeManagingSession.batchTargetNodes && nodeManagingSession.batchTargetNodes!.length > 0;

        let steps: { label }[] = [];
        let activeStep = 0;

        let dialogTitle = "";
        let buttonOkTitle = "";
        let buttonCancelTitle: string | undefined = undefined;
        let buttonOkDisabled = true;
        let buttonCancelDisabled = false;
        let dialogFullscreen = false;
        let dialogActions: any = undefined;
        let onOkHandler: () => void = () => {
        };

        const fetchingConfigurationStep = {label: nodeManagingSession?.selectedSoftware?.name === "dms_client" ? "Select the configuration" : "Fetching configuration from Bitbucket"};

        switch (nodeManagingSession.sessionMode) {
            case "software":
                if (nodeManagingSession.selectedSoftware && nodeManagingSession.selectedSoftware.manifest) {
                    const versionString = `${nodeManagingSession.selectedSoftware.manifest.version.major}.${nodeManagingSession.selectedSoftware.manifest.version.minor}.${nodeManagingSession.selectedSoftware.manifest.version.build}`;
                    dialogTitle = `Update ${nodeManagingSession.selectedSoftware.name} (${versionString}) on ${nodeManagingSession.targetNode!.uid}`;
                } else {
                    dialogTitle = `Update Software on ${nodeManagingSession.targetNode!.uid}`;
                }

                steps = [
                    {label: "Getting node software"},
                    {label: "Select the software to update"},
                    {label: "Select the update payload"},
                    {label: "Updating the software"}
                ];
                break;
            case "batch-software":
                dialogTitle = `${nodeManagingSession.batchUpdateSequentially ? "Sequentially " : ""}Update Software on ${nodeManagingSession.batchTargetNodes?.length} nodes`;
                steps = [
                    {label: "Getting software"},
                    {label: "Select the software to update"},
                    {label: "Select the update payload"},
                    {label: "Updating the software"}
                ];
                break;
            case "batch-restart":
                dialogTitle = `Restart Software on ${nodeManagingSession.batchTargetNodes?.length} nodes`;

                steps = [
                    {label: "Getting software"},
                    {label: "Select the software to restart"},
                    {label: "Restarting software"}
                ];
                break;
            case "batch-config":
                dialogTitle = `${nodeManagingSession.batchUpdateSequentially ? "Sequentially " : ""}Update Config on ${nodeManagingSession.batchTargetNodes?.length} nodes`;
                steps = [
                    {label: "Getting software"},
                    {label: "Select the software to update"},
                    {label: "Fetching configuration from Bitbucket"},
                    {label: "Updating the configuration"}
                ];
                break;
            case "config":
                if (nodeManagingSession.selectedSoftware && nodeManagingSession.selectedSoftware.manifest) {
                    dialogTitle = `Update config for ${nodeManagingSession.selectedSoftware.name} on ${nodeManagingSession.targetNode!.uid}`;
                } else {
                    dialogTitle = `Update Config on ${nodeManagingSession.targetNode!.uid}`;
                }

                steps = [
                    {label: "Getting node software"},
                    {label: "Select the software to update"},
                    fetchingConfigurationStep,
                    {label: "Verify the configuration"},
                    {label: "Updating the configuration"}
                ];
                break;
            case "restart":
                dialogTitle = `Restart software on ${nodeManagingSession.targetNode!.uid}`;

                steps = [
                    {label: "Getting node software"},
                    {label: "Select the software to restart"},
                    {label: "Restarting software"}
                ];
                break;
            case "logs":
                if (nodeManagingSession.selectedSoftware) {
                    dialogTitle = `View ${nodeManagingSession.selectedSoftware.name} logs on ${nodeManagingSession.nodeLog ? "node " : ""}${nodeManagingSession.targetNode!.uid}`;
                } else {
                    dialogTitle = `View logs on ${nodeManagingSession.targetNode!.uid}`;
                }

                dialogFullscreen = false;

                steps = [
                    {label: "Getting node software"},
                    {label: "Select software"},
                    {label: "Getting logs"},
                    {label: "Viewing log"}
                ];
                break;
            case "actions":
                dialogTitle = `Actions on ${nodeManagingSession.targetNode!.uid}`;

                steps = [
                    {label: "Listing actions"},
                    {label: "Select an action"},
                    {label: "Executing action"},
                ];
                break;
        }

        switch (nodeManagingSession.state) {
            case DMSNodeManagingSessionState.READY:
                activeStep = -1;
                break;
            case DMSNodeManagingSessionState.GETTING_NODE_SOFTWARE:
                activeStep = 0;
                break;
            case DMSNodeManagingSessionState.GOT_NODE_SOFTWARE:
                buttonOkTitle = "Continue";
                buttonOkDisabled = false;
                onOkHandler = () => {
                    this.setState(prevState => ({
                        nodeManagingSession: {
                            ...nodeManagingSession,
                            state: DMSNodeManagingSessionState.SELECTING_SOFTWARE_TO_UPDATE,
                        }
                    }));
                };
                break;
            case DMSNodeManagingSessionState.SELECTING_SOFTWARE_TO_UPDATE:
                activeStep = 1;
                break;
            case DMSNodeManagingSessionState.BATCH_GETTING_LATEST_CONFIG_FROM_BITBUCKET:
                activeStep = 2;
                break;
            case DMSNodeManagingSessionState.GETTING_LATEST_CONFIG_FROM_BITBUCKET:
                activeStep = 2;
                break;
            case DMSNodeManagingSessionState.BATCH_GOT_CONFIG_FROM_BITBUCKET:
            case DMSNodeManagingSessionState.GOT_CONFIG_FROM_BITBUCKET:
                buttonOkTitle = "Update Config";
                activeStep = 3;
                onOkHandler = () => {
                    if (nodeManagingSession.batchTargetNodes) {
                        this.updateConfigOnNodes(nodeManagingSession);
                    } else {
                        this.updateConfigOnNode(nodeManagingSession);
                    }
                };
                buttonOkDisabled = false;
                break;
            case DMSNodeManagingSessionState.COPY_CONFIG_FROM_NODE:
                activeStep = 2;

                if (nodeManagingSession.copyFromNode) {
                    buttonOkTitle = "Continue";
                    buttonOkDisabled = false;
                    onOkHandler = async () => {
                        await this.getNodeConfigFromBitbucket(this.state.nodeManagingSession!);
                    };
                }
                break;
            case DMSNodeManagingSessionState.SELECTING_UPDATE_PAYLOAD:
                activeStep = 2;
                break;
            case DMSNodeManagingSessionState.UPDATING_SOFTWARE_SEQUENTIALLY:
            case DMSNodeManagingSessionState.UPDATING_SOFTWARE:
                activeStep = 3;
                buttonCancelDisabled = true;
                break;
            case DMSNodeManagingSessionState.UPDATING_CONFIG:
                activeStep = 4;
                buttonCancelDisabled = true;
                break;
            case DMSNodeManagingSessionState.NODE_RESTARTING_SOFTWARE:
                activeStep = 2;
                buttonCancelDisabled = true;
                break;
            case DMSNodeManagingSessionState.NODE_DOWNLOADING_SOFTWARE:
                activeStep = 3;
                break;
            case DMSNodeManagingSessionState.NODE_INSTALLING_SOFTWARE:
                activeStep = 3;
                break;
            case DMSNodeManagingSessionState.NODE_UPDATE_SUCCEEDED:
                break;
            case DMSNodeManagingSessionState.NODE_UPDATE_FAILED:
                break;
            case DMSNodeManagingSessionState.NODE_UPDATE_CANCELLED:
                break;
            case DMSNodeManagingSessionState.LISTING_NODE_LOGS:
                activeStep = 2;
                break;
            case DMSNodeManagingSessionState.LISTED_NODE_LOGS:
                activeStep = 2;
                break;
            case DMSNodeManagingSessionState.GETTING_NODE_LOG:
                activeStep = 3;
                break;
            case DMSNodeManagingSessionState.LISTED_NODE_BRIDGE_ACTIONS:
                activeStep = 1;
                break;
            case DMSNodeManagingSessionState.CONFIGURE_NODE_BRIDGE_ACTION:
                steps = [
                    {label: "Listing actions"},
                    {label: "Select an action"},
                    {label: "Configure action"},
                    {label: "Executing action"}
                ];
                activeStep = 2;
                buttonOkTitle = "Execute";
                buttonOkDisabled = false;
                onOkHandler = () => {
                    this.executeBridgeAction(nodeManagingSession.selectedAction!);
                };
                break;
            case DMSNodeManagingSessionState.EXECUTED_NODE_BRIDGE_ACTION:
            case DMSNodeManagingSessionState.EXECUTED_NODE_BRIDGE_ACTION_WITH_RESULT:
                if ((nodeManagingSession?.selectedAction?.parameters?.length ?? 0) > 0) {
                    steps = [
                        {label: "Listing actions"},
                        {label: "Select an action"},
                        {label: "Configure action"},
                        {label: "Executing action"}
                    ];
                }


                dialogTitle = `Action '${nodeManagingSession.selectedAction?.Id}' on ${nodeManagingSession.targetNode!.uid}`;

                activeStep = 3;
                buttonOkTitle = "Back";
                buttonOkDisabled = false;
                onOkHandler = () => {
                    this.setState({
                        nodeManagingSession: {
                            ...this.state.nodeManagingSession!,
                            state: DMSNodeManagingSessionState.LISTED_NODE_BRIDGE_ACTIONS,
                            actionResult: undefined,
                            selectedAction: undefined
                        }
                    });
                };
                break;
            case DMSNodeManagingSessionState.EXECUTING_NODE_BRIDGE_ACTION:
                if ((nodeManagingSession?.selectedAction?.parameters?.length ?? 0) > 0) {
                    steps = [
                        {label: "Listing actions"},
                        {label: "Select an action"},
                        {label: "Configure action"},
                        {label: "Executing action"}
                    ];
                }


                dialogTitle = `Executing action '${nodeManagingSession.selectedAction?.Id}' on ${nodeManagingSession.targetNode!.uid}`;

                activeStep = 3;
                break;
            case DMSNodeManagingSessionState.GOT_NODE_LOG:
                break;
        }

        return (<div>
            <ModalDialog
                open={nodeManagingSession.state !== DMSNodeManagingSessionState.UPDATING_SOFTWARE && nodeManagingSession.state !== DMSNodeManagingSessionState.NODE_UPDATE_SUCCEEDED && nodeManagingSession.state !== DMSNodeManagingSessionState.NODE_UPDATE_FAILED}
                title={dialogTitle}
                fullScreen={dialogFullscreen}
                buttonOkTitle={buttonOkTitle}
                buttonOkDisabled={buttonOkDisabled}
                maxWidth={"xl"}
                buttonCancelTitle={buttonCancelTitle ?? undefined}
                buttonCancelDisabled={buttonCancelDisabled}
                buttonOkComponent={dialogActions}
                onOk={onOkHandler}
                onCancel={() => {
                    this.setState({
                        nodeManagingSession: undefined
                    });
                }}>
                <div className={classes.backdropMessage} style={{height: "100%", width: "100%"}}>
                    <Stepper activeStep={activeStep}>
                        {steps.map((step) => {
                            const stepProps: { completed?: boolean } = {};
                            return (
                                <Step key={step.label} {...stepProps}>
                                    <StepLabel>{step.label}</StepLabel>
                                </Step>
                            );
                        })}
                    </Stepper>
                    <div className={classes.backdropMessage} style={{height: "100%", width: "100%", marginTop: 16}}>
                        {
                            this.renderUpdateSteps(installedSoftware, isBatchUpdate)
                        }
                    </div>
                </div>
                <ModalDialog
                    fullScreen={true}
                    open={nodeManagingSession.state === DMSNodeManagingSessionState.GOT_NODE_LOG || nodeManagingSession.state === DMSNodeManagingSessionState.GETTING_NODE_LOG}
                    title={nodeManagingSession?.nodeLog?.name ?? "Log"}
                    onCancel={() => {
                        if (this.state.nodeManagingSession) {
                            this.setState({
                                nodeManagingSession: {
                                    ...this.state.nodeManagingSession,
                                    state: DMSNodeManagingSessionState.LISTED_NODE_LOGS
                                }
                            });
                        }
                    }}
                    buttonOkComponent={<div>
                        <IconButton edge="start"
                                    color="inherit" onClick={() => {
                            navigator.clipboard.writeText(nodeManagingSession?.nodeLog!.contents!);
                            this.displaySnackbar(`Copied contents of ${nodeManagingSession?.nodeLog!.name} to clipboard`);
                        }}><FileCopyIcon/></IconButton>
                        <IconButton edge="start"
                                    color="inherit" onClick={() => {
                            fileDownload(nodeManagingSession?.nodeLog!.contents!, nodeManagingSession?.nodeLog!.name, 'text/plain');
                        }}><CloudDownloadIcon/></IconButton>
                        <IconButton edge="start"
                                    color="inherit" onClick={() => {
                            this.getNodeLog(nodeManagingSession, nodeManagingSession?.nodeLog!.name);
                        }}><RefreshIcon/></IconButton>
                    </div>}>
                    <div className={classes.backdropMessage} style={{height: "100%", width: "100%"}}>
                        {
                            this.renderUpdateSteps(installedSoftware, isBatchUpdate)
                        }
                    </div>
                </ModalDialog>
            </ModalDialog>
        </div>);
    };

    renderDeleteNodeDialog() {
        const {deletionTargetNode, deletionTargetNodeNameConfirmation} = this.state;

        if (!deletionTargetNode) {
            return;
        }

        const {isLoadingNodes} = this.props;

        const canContinue = deletionTargetNode?.uid === deletionTargetNodeNameConfirmation;

        return (<ModalDialog open={true}
                             buttonOkDisabled={this.state.isDeletingNodeInProgress || !canContinue}
                             buttonCancelDisabled={this.state.isDeletingNodeInProgress}
                             buttonOkIsLoading={this.state.isDeletingNodeInProgress || isLoadingNodes}
                             title={`Are you sure you want to delete '${this.state?.deletionTargetNode?.uid}@${this.state.deletionTargetNode?.location}'`}
                             onOk={() => {
                                 this.onDeleteNodeRequested(this.state.deletionTargetNode!);
                             }} onCancel={() => {
            this.setState({deletionTargetNode: undefined});
        }}>
            {"This action is permanent and cannot be undone!"}
            <TextField value={this.state.deletionTargetNodeNameConfirmation ?? ""}
                       disabled={this.state.isDeletingNodeInProgress}
                       onChange={(event) => {
                           this.setState({
                               deletionTargetNodeNameConfirmation: event.target.value
                           });
                       }}
                       fullWidth
                       label={"Enter the name of the node to confirm the deletion"}/>
        </ModalDialog>);
    };

    renderGetNodeConfigDialog() {
        const {isGettingNodeConfigInProgress, nodeConfig, getConfigTargetNode} = this.state;

        if ((!isGettingNodeConfigInProgress && nodeConfig === undefined) || getConfigTargetNode === undefined) {
            return;
        }

        return (<ModalDialog
            maxWidth={"lg"}
            open={true}
            buttonOkDisabled={isGettingNodeConfigInProgress}
            buttonOkIsLoading={isGettingNodeConfigInProgress}
            hideCancelButton={true}
            title={"Config for " + getConfigTargetNode.uid}
            onOk={() => {
                this.setState({
                    nodeConfig: undefined,
                    getConfigTargetNode: undefined
                });
            }}
            onCancel={() => {
                this.setState({
                    nodeConfig: undefined,
                    getConfigTargetNode: undefined
                });
            }}
        >
            {nodeConfig ? <LazyLog
                selectableLines
                extraLines={0}
                height={500}
                follow={true}
                enableSearch={true}
                caseInsensitive={true}
                text={JSON.stringify(nodeConfig, null, 4)}/> : <Typography>Loading config...</Typography>}
        </ModalDialog>);
    };

    private renderSoftwareTable = (installedSoftware?: IDMSSoftwareBundle[], rowClickabilityHandler?: (s: IDMSSoftwareBundle) => boolean, onRowSelected?: (s: IDMSSoftwareBundle, index: number) => void, isBatchUpdate = false) => {
        const {classes} = this.props;

        const {nodeInfoDialogState} = this.state;

        const nodeInfoTarget = nodeInfoDialogState?.targetNode;

        return <TableContainer>
            <Table size={"small"} className={classes.table}>
                <TableHead>
                    <TableRow>
                        <TableCell>Software</TableCell>
                        {isBatchUpdate ? undefined : <TableCell align={"right"}>Version</TableCell>}
                        {isBatchUpdate ? undefined : <TableCell align={"center"}>Working Directory</TableCell>}
                        <TableCell align={"center"}>Update Strategy</TableCell>
                        <TableCell align={"center"}>Health</TableCell>
                        {isBatchUpdate ? undefined : <TableCell align={"center"}>Running</TableCell>}
                        {isBatchUpdate || rowClickabilityHandler ? undefined :
                            <TableCell align={"center"}>Configuration</TableCell>}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {installedSoftware?.map((software, index) => {
                        let softwareVersionString = "Not Installed";

                        let listItemDisabled = rowClickabilityHandler ? rowClickabilityHandler(software) : false;

                        if (software.manifest) {
                            softwareVersionString = software.manifest.version.major + "." + software.manifest.version.minor + "." + software.manifest.version.build;
                        }

                        const nodeHealthState = (nodeInfoDialogState?.healthCheckResult ?? []).find(h => h.softwareName === software.name);
                        let nodeHealthStateComponent: any;

                        if (nodeHealthState) {
                            if (nodeHealthState.isHealthy) {
                                nodeHealthStateComponent =
                                    <Tooltip
                                        title={nodeHealthState?.responseCode ? (nodeHealthState.message + " " + nodeHealthState?.responseCode.toString()) : "Healthy"}>
                                        <CheckCircleIcon htmlColor={"rgb(81, 176, 51)"}/>
                                    </Tooltip>;
                            } else {
                                nodeHealthStateComponent =
                                    <Tooltip
                                        title={nodeHealthState?.responseCode ? (nodeHealthState.message + " " + nodeHealthState?.responseCode.toString()) : "Unhealthy"}>
                                        <ErrorIcon htmlColor={"rgba(119, 97, 64, 0.85)"}/>
                                    </Tooltip>;
                            }
                        } else {
                            nodeHealthStateComponent = <Tooltip title={"Not Configured"}><HelpIcon/></Tooltip>;
                        }

                        return (
                            <TableRow
                                hover={!listItemDisabled} key={index.toString()}
                                onClick={() => {
                                    if (!listItemDisabled && onRowSelected) {
                                        onRowSelected(software, index);
                                    }
                                }}>

                                <Tooltip title={JSON.stringify(software, undefined, 4)}>
                                    <TableCell>{software!.name}</TableCell>
                                </Tooltip>

                                {isBatchUpdate ? undefined :
                                    <TableCell align={"right"}>{softwareVersionString}</TableCell>}
                                {isBatchUpdate ? undefined : <TableCell align={"right"}>{
                                    <TextField
                                        size={"small"}
                                        variant={"standard"}
                                        disabled={true}
                                        value={software!.workingDirectory ?? ""}
                                        fullWidth
                                        InputProps={{
                                            readOnly: true,
                                        }}/>
                                }
                                </TableCell>}
                                <TableCell align={"center"}>
                                    <Chip
                                        variant={"outlined"}
                                        size={"small"}
                                        label={software!.updateStrategy}/>
                                </TableCell>
                                <TableCell align={"center"}>
                                    {nodeHealthStateComponent}
                                </TableCell>
                                {isBatchUpdate ? undefined : <TableCell align={"center"}>
                                    <Chip
                                        variant={"outlined"}
                                        size={"small"}
                                        style={software!.isRunning ?
                                            {
                                                color: "white",
                                                backgroundColor: "rgb(0, 180, 0)"
                                            } :
                                            {
                                                color: "white",
                                                backgroundColor: "rgba(119, 97, 64, 0.85)"
                                            }
                                        }
                                        icon={software!.isRunning ? <CheckCircleIcon/> :
                                            <ErrorIcon/>}
                                        deleteIcon={software!.manifest ?
                                            <PlayCircleFilledWhiteIcon/> : undefined}
                                        onDelete={software!.manifest && nodeInfoDialogState?.targetNode ? async () => {
                                            const nodeManagingSession: IDMSNodeManagingSession = {
                                                targetNode: nodeInfoTarget!,
                                                sessionMode: "restart",
                                                state: DMSNodeManagingSessionState.READY,
                                                selectedSoftware: software,
                                                batchUpdateProgress: 0
                                            };
                                            await this.restartSoftwareOnNode(nodeManagingSession);
                                            await this.onShowNodeInfoRequested(nodeInfoTarget!);
                                        } : undefined}
                                        label={software!.isRunning ? "Yes" : "No"}/>
                                </TableCell>}
                                {isBatchUpdate || rowClickabilityHandler ? undefined : <TableCell align={"center"}>
                                    <Button disabled={software.manifest === undefined} onClick={() => {
                                        this.getSoftwareConfig(software.name, nodeInfoDialogState!);
                                    }} size={"small"} variant={"outlined"}>View</Button>
                                </TableCell>}
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>;
    };

    renderNewNodeDialog() {
        const {classes} = this.props;
        const {newNodeRequest, isCreatingNodeInProgress} = this.state;

        if (!newNodeRequest) {
            return;
        }

        const hasCreatedNode = newNodeRequest?.createdToken !== undefined;
        let newNodeConfig: IDMSClientConfig | undefined;
        let dialogMessage = hasCreatedNode ? `⚠️ Please make sure that you store and transfer this configuration file securely, it contains sensitive information and will only be shown once, the node will have to be recreated if this configuration is lost!` : "";
        let dialogTitle = hasCreatedNode ? `Registered node '${newNodeRequest?.nodeName}'` : "Register a new node";

        if (hasCreatedNode) {
            const dmsHost = EndpointHelper.getDmsHostname(Constants.env);
            const dmsPortNumber = EndpointHelper.getDmsPortNumber(Constants.env);

            newNodeConfig = {
                hostname: dmsHost,
                port: dmsPortNumber,
                nodeName: newNodeRequest?.nodeName!,
                jwtToken: newNodeRequest?.createdToken!,
                proxyHostname: newNodeRequest?.proxyHostname!,
                installedSoftware: [
                    {
                        name: "e.g. tagomat | bagomat",
                        workingDirectory: "e.g. c:\\tgm",
                        updateStrategy: DMSUpdateStrategy.KILL_REPLACE_START
                    }
                ]
            };

            if (newNodeRequest?.definedSoftware) {
                const matchingSoftware = this.props.definedSoftware.filter(s => (newNodeRequest!.definedSoftware?.indexOf(s.name) ?? -1) > -1);

                if (matchingSoftware) {
                    newNodeConfig.installedSoftware = matchingSoftware.map(s => {
                        return {
                            name: s.name,
                            workingDirectory: s.workingDirectory,
                            updateStrategy: s.updateStrategy
                        } as IDMSSoftwareBundle;
                    });
                }
            }

            if (newNodeRequest.enableSplunk) {
                newNodeConfig.installedSoftware?.push({
                    name: "splunk-installer",
                    workingDirectory: "c:\\util",
                    updateStrategy: DMSUpdateStrategy.KILL_REPLACE_START
                });
            }
        }

        return (<ModalDialog open={true}
                             buttonOkIsLoading={isCreatingNodeInProgress}
                             buttonOkDisabled={!newNodeRequest?.validates || isCreatingNodeInProgress}
                             buttonCancelDisabled={isCreatingNodeInProgress}
                             title={dialogTitle}
                             buttonOkTitle={hasCreatedNode ? "Download" : "Register"}
                             buttonCancelTitle={hasCreatedNode ? "Close" : "Cancel"}
                             message={dialogMessage}
                             onOk={() => {
                                 if (hasCreatedNode) {
                                     fileDownload(JSON.stringify(newNodeConfig, null, 4), "config.json", 'application/json');
                                 } else if (newNodeRequest) {
                                     this.onCreateNodeRequested(newNodeRequest);
                                 }
                             }} onCancel={() => {
            this.setState({newNodeRequest: undefined});
        }}>
            <Container>
                {
                    newNodeRequest?.createdToken
                        ? (<TextField
                            value={JSON.stringify(newNodeConfig, null, 4)}
                            multiline
                            maxRows={50}
                            fullWidth
                            InputProps={{
                                readOnly: true,
                            }}
                            label={"Node Config"}/>)
                        : (<div>
                            <FormControl style={{minWidth: "120px"}} fullWidth={true}>
                                <InputLabel>Software Type</InputLabel>
                                <Select
                                    value={newNodeRequest?.definedSoftware ?? []}
                                    multiple
                                    onChange={(event) => {
                                        const {
                                            target: {value},
                                        } = event;
                                        if (value) {
                                            const definedSoftware = typeof value === 'string' ? value.split(',') : value as string[];

                                            this.setState(prevState => ({
                                                newNodeRequest: {
                                                    ...prevState.newNodeRequest,
                                                    definedSoftware
                                                }
                                            }), () => this.validateNewNodeRequest(newNodeRequest));

                                            console.log(definedSoftware);
                                        }
                                    }}
                                    renderValue={(selected) => (
                                        <Box sx={{display: 'flex', flexWrap: 'wrap', gap: 0.25}}>
                                            {selected.map((value) => (
                                                <Chip key={value} label={value}/>
                                            ))}
                                        </Box>
                                    )}
                                >
                                    {
                                        this.props.definedSoftware.map((k, key) => {
                                            return <MenuItem key={key} value={k.name}>{`${k.name}`}</MenuItem>;
                                        })
                                    }
                                </Select>
                            </FormControl>
                            <TextField value={newNodeRequest?.nodeName ?? ""}
                                       disabled={isCreatingNodeInProgress}
                                       onChange={(event) => {
                                           let newValue = "";
                                           if (event && event.target && event.target.value) {
                                               newValue = event.target.value;
                                           }

                                           this.setState(prevState => ({
                                               newNodeRequest: {
                                                   ...prevState.newNodeRequest,
                                                   nodeName: newValue.toLowerCase()
                                               }
                                           }), () => {
                                               this.validateNewNodeRequest(newNodeRequest);
                                           });
                                       }}
                                       fullWidth
                                       label={"Node Name"}/>
                            <TextField value={newNodeRequest?.location ?? ""}
                                       disabled={isCreatingNodeInProgress}
                                       onChange={(event) => {
                                           let newValue = "";
                                           if (event && event.target && event.target.value) {
                                               newValue = event.target.value;
                                           }

                                           this.setState(prevState => ({
                                               newNodeRequest: {
                                                   ...newNodeRequest,
                                                   location: newValue.toLowerCase()
                                               }
                                           }), () => {
                                               this.validateNewNodeRequest(newNodeRequest);
                                           });
                                       }}
                                       className={classes.dialogTextField}
                                       fullWidth
                                       label={"Location"}/>
                            <TextField value={newNodeRequest?.proxyHostname ?? ""}
                                       disabled={isCreatingNodeInProgress}
                                       onChange={(event) => {
                                           let newValue = "";
                                           if (event && event.target && event.target.value) {
                                               newValue = event.target.value;
                                           }

                                           this.setState(prevState => ({
                                               newNodeRequest: {
                                                   ...newNodeRequest,
                                                   proxyHostname: newValue
                                               }
                                           }), () => {
                                               this.validateNewNodeRequest(newNodeRequest);
                                           });
                                       }}
                                       className={classes.dialogTextField}
                                       fullWidth
                                       label={"Proxy Hostname (only used for Avinor)"}/>

                            <Checkbox
                                disabled={isCreatingNodeInProgress}
                                checked={newNodeRequest?.enableSplunk}

                                onChange={(event) => {
                                    let isChecked = event.target.checked; // Check if the checkbox is checked or unchecked

                                    this.setState(prevState => ({
                                        newNodeRequest: {
                                            ...newNodeRequest,
                                            enableSplunk: isChecked
                                        }
                                    }), () => {
                                        this.validateNewNodeRequest(newNodeRequest);
                                    });

                                }}
                                color="primary"/>Enable Splunk (BUD)
                        </div>)}
            </Container>
        </ModalDialog>);
    };

    renderNodeInfoDialog() {
        const {nodeInfoDialogState} = this.state;

        const nodeInfoTarget = nodeInfoDialogState?.targetNode;

        if (!nodeInfoTarget) {
            return;
        }

        const {classes, mode, dmsOnlineNodes} = this.props;

        const isNodeOnline = dmsOnlineNodes[nodeInfoTarget?.uid] !== undefined;

        let nodeVersionString = "";

        if (nodeInfoTarget && nodeInfoTarget.clientVersion) {
            nodeVersionString = nodeInfoTarget.clientVersion.major + "." + nodeInfoTarget.clientVersion.minor + "." + nodeInfoTarget.clientVersion.build;
        }

        let nodeIsOutdated = false;

        if (nodeInfoTarget && nodeInfoTarget.clientVersion && this.props.globalSettings?.preferredClientVersions) {
            const preferredVersion = this.props.globalSettings.preferredClientVersions[nodeInfoTarget.clientName];

            if (preferredVersion) {
                nodeIsOutdated = Util.compareVersions(nodeInfoTarget.clientVersion, preferredVersion) < 0;
            }
        }

        const shouldDisplayPortForwardingTab = this.props.targetNodeInfoModeConfig?.targetNode === undefined;

        return (<ModalDialog
            modalScroll={this.props.targetNodeInfoModeConfig?.targetNode ? "paper" : "body"}
            open={Boolean(nodeInfoTarget)}
            fullScreen={Boolean(this.props.targetNodeInfoModeConfig)}
            title={this.props.targetNodeInfoModeConfig?.targetNode ? nodeInfoTarget.uid : ""}
            titleComponent={<div style={{
                flexDirection: "row",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                justifySelf: "center"
            }}>
                <Typography variant="h6">{nodeInfoTarget?.uid ?? ""}</Typography>
                <Typography variant={"caption"} style={{paddingLeft: 4, paddingRight: 4}}>• Registered by •</Typography>
                <DMSUserView
                    chipSize={"small"}
                    username={nodeInfoTarget?.createdBy ?? "dms_server"}
                    classes={classes}
                    isUserOnline={(username => {
                        return username === "dms_server" || (dmsOnlineNodes && dmsOnlineNodes[nodeInfoTarget?.createdBy ?? ""] !== undefined);
                    })}/>
                <div style={{position: "absolute", right: appTheme.spacing(1.5), top: appTheme.spacing(1.5)}}>
                    <div style={{display: "flex", justifyContent: "center", alignItems: "center", gap: 4}}>
                        <Slide in={mode === "nodes"} direction={"right"} timeout={500}>
                            <IconButton
                                color={"primary"}
                                size={"large"}
                                onClick={() => {
                                    this.captureScreenshot({targetNode: nodeInfoTarget});
                                }}>
                                <CameraAlt/>
                            </IconButton>
                        </Slide>
                        <Fade
                            style={{display: isNodeOnline ? "block" : "none"}}
                            in={isNodeOnline}>
                            <SignalCellularAltTwoToneIcon aria-label={"online"} htmlColor="rgb(0,225,0)"/></Fade>
                        <Fade
                            style={{display: isNodeOnline ? "none" : "block"}}
                            in={!isNodeOnline}>
                            <SignalCellularConnectedNoInternet0BarTwoToneIcon aria-label={"offline"}
                                                                              htmlColor={"orange"}/></Fade>
                    </div>
                </div>
            </div>}
            disableBackdropClick={false}
            disableEscapeKeyDown={false}
            buttonOkTitle={"Save"}
            maxWidth={false}
            buttonOkComponent={this.props.targetNodeInfoModeConfig?.targetNode
                ? <IconButton
                    size={"large"}
                    color={"primary"}
                    onClick={() => {
                        this.captureScreenshot({targetNode: nodeInfoTarget});
                    }}>
                    <CameraAlt/>
                </IconButton> : undefined}
            buttonOkDisabled={nodeInfoDialogState?.isUpdatingNode || this.state.selectedNodeInfoTab !== 0 || mode !== "nodes"}
            buttonCancelTitle={"Close"}
            onOk={() => {
                if (nodeInfoTarget) {
                    this.saveNodeInfo();
                }
            }}
            onCancel={() => {
                if (this.props.targetNodeInfoModeConfig?.onCancel) {
                    this.props.targetNodeInfoModeConfig.onCancel();
                } else {
                    this.setState({
                        nodeInfoDialogState: undefined,
                        selectedNodeInfoTab: 0
                    });
                }
            }}>
            {
                <ModalDialog
                    open={nodeInfoDialogState?.softwareConfig !== undefined ?? false}
                    title={"Config for " + nodeInfoDialogState?.softwareConfig?.softwareName + " on " + nodeInfoDialogState?.targetNode?.uid ?? ""}
                    fullScreenComponent={
                        <FormControlLabel
                            labelPlacement={"start"}
                            control={
                                <Switch
                                    disabled={nodeInfoDialogState?.softwareConfig?.easyModeLoading}
                                    onChange={(e) => {
                                        const checked = e.target?.checked ?? false;
                                        this.setState(prevState => ({
                                            nodeInfoDialogState: {
                                                ...prevState.nodeInfoDialogState!,
                                                softwareConfig: {
                                                    ...prevState.nodeInfoDialogState?.softwareConfig!,
                                                    easyMode: checked
                                                }
                                            }
                                        }));
                                    }}
                                    checked={nodeInfoDialogState?.softwareConfig?.easyMode ?? false}
                                    color="primary"
                                />
                            }
                            label={"Easy Mode"}
                        />
                    }
                    hideCancelButton={true}
                    disableBackdropClick={false}
                    disableEscapeKeyDown={false}
                    fullScreen={true}
                    onCancel={() => {
                        this.setState(prevState => ({
                            nodeInfoDialogState: {
                                ...prevState.nodeInfoDialogState,
                                softwareConfig: undefined,
                            }
                        }));
                    }}
                    hideOkButton={true}>
                    {
                        nodeInfoDialogState?.softwareConfig?.easyMode
                            ? <DMSDataRenderer
                                onLoadingChanged={(isLoading) => {
                                    nodeInfoDialogState!.softwareConfig!.easyModeLoading = isLoading;
                                    this.forceUpdate();
                                }}
                                configContents={nodeInfoDialogState.softwareConfig.contents ?? ""}
                                enqueueSnackbar={this.props.enqueueSnackbar}
                                closeSnackbar={this.props.closeSnackbar}
                                softwareName={nodeInfoDialogState.softwareConfig.softwareName}
                                classes={classes}/>
                            : <LazyLog
                                selectableLines
                                extraLines={0}
                                height={"auto"}
                                follow={true}
                                enableSearch={true}
                                caseInsensitive={true}
                                text={nodeInfoDialogState?.softwareConfig?.contents}/>
                    }
                </ModalDialog>
            }
            <div>
                <Tabs variant={"fullWidth"} value={this.state.selectedNodeInfoTab} onChange={(event, newValue) => {
                    this.setState({
                        selectedNodeInfoTab: newValue
                    });
                }}>
                    <Tab label="Node Info"/>
                    <Tab style={{opacity: shouldDisplayPortForwardingTab ? 1 : 0.25}}
                         disabled={!shouldDisplayPortForwardingTab || mode !== "nodes"} label="Port Forwarding"/>
                    <Tab label="Node Log" disabled={mode !== "nodes"}/>
                    <Tab label="Bridge Messages" disabled={mode !== "nodes"}/>
                    <Tab label="Node Software"/>
                </Tabs>
                <Zoom unmountOnExit={true} in={this.state.selectedNodeInfoTab === 0} exit={true}>
                    <div role="tabpanel" style={{marginTop: "8px"}} hidden={this.state.selectedNodeInfoTab !== 0}>
                        <TextField
                            size={"small"}
                            variant={"standard"}
                            value={nodeInfoTarget?.lastConnection ? Util.relativeDateTimeStringFromDBTimestamp(nodeInfoTarget?.lastConnection!, true) : ""}
                            className={classes.dialogTextField}
                            fullWidth
                            disabled
                            InputProps={{
                                readOnly: true,
                            }}
                            label={"Last Connection"}/>
                        <TextField
                            size={"small"}
                            variant={"standard"}
                            value={nodeInfoTarget?.location ?? ""}
                            disabled={this.props.targetNodeInfoModeConfig?.targetNode ? true : this.props.mode !== "nodes"}
                            className={classes.dialogTextField}
                            onChange={(event) => {
                                let newValue = "";
                                if (event && event.target && event.target.value) {
                                    newValue = event.target.value as string;
                                }

                                this.setState({
                                    nodeInfoDialogState: {
                                        ...this.state.nodeInfoDialogState,
                                        targetNode: this.state.nodeInfoDialogState?.targetNode ? {
                                            ...this.state.nodeInfoDialogState.targetNode,
                                            location: newValue
                                        } : undefined
                                    }
                                });
                            }}
                            fullWidth
                            InputProps={{
                                readOnly: false,
                            }}
                            label={this.props.mode === "nodes" ? "Location" : "Membership"}/>
                        <TextField
                            size={"small"}
                            variant={"standard"}
                            value={nodeInfoTarget?.lastLogEntry?.state ?? "No State Reported"}
                            className={classes.dialogTextField}
                            fullWidth
                            disabled
                            InputProps={{
                                readOnly: true,
                            }}
                            label={"Client State"}/>
                        <TextField
                            size={"small"}
                            variant={"standard"}
                            value={(nodeInfoTarget?.operatingSystem ?? "").length > 0 ? nodeInfoTarget?.operatingSystem : "No OS Reported"}
                            className={classes.dialogTextField}
                            fullWidth
                            disabled
                            InputProps={{
                                readOnly: true,
                            }}
                            label={"OS"}/>
                        <TextField
                            size={"small"}
                            variant={"standard"}
                            value={(nodeInfoTarget?.cpuArch ?? "No Arch Reported")}
                            className={classes.dialogTextField}
                            fullWidth
                            disabled
                            InputProps={{
                                readOnly: true,
                            }}
                            label={"CPU Architecture"}/>
                        <TextField
                            size={"small"}
                            variant={"standard"}
                            value={nodeInfoTarget?.clientName ? `${nodeInfoTarget?.clientName} (${nodeVersionString}${nodeIsOutdated ? " - Outdated" : ""})` : "No Client Reported"}
                            className={classes.dialogTextField}
                            fullWidth
                            disabled
                            InputProps={{
                                readOnly: true,
                            }}
                            label={"Client"}/>
                        <TextField
                            size={"small"}
                            variant={"standard"}
                            value={(nodeInfoTarget?.lastIp ?? "").length > 0 ? `${nodeInfoTarget?.lastIp}` : "No IP Reported"}
                            className={classes.dialogTextField}
                            disabled
                            fullWidth
                            InputProps={{
                                readOnly: true,
                                endAdornment: <IconButton onClick={() => {
                                    if (nodeInfoTarget?.lastIp) {
                                        window.open("https://www.whois.com/whois/" + nodeInfoTarget?.lastIp, "_blank");
                                    }
                                }}>
                                    <QuestionMark/>
                                </IconButton>
                            }}
                            label={"Last IP"}/>
                        {
                            (nodeInfoTarget.networkAdapters ?? []).map((adapter, key) => {
                                return <TextField
                                    variant={"standard"}
                                    size={"small"}
                                    key={key}
                                    value={adapter.address}
                                    className={classes.dialogTextField}
                                    fullWidth
                                    disabled
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    label={"Local Adapter: " + adapter.name}/>;
                            })
                        }
                        <TextField
                            size={"small"}
                            variant={"standard"}
                            value={dmsOnlineNodes[nodeInfoTarget.uid]?.ipcWorker ?? "N/A"}
                            className={classes.dialogTextField}
                            fullWidth
                            disabled
                            InputProps={{
                                readOnly: true,
                            }}
                            label={"Cluster Instance"}
                        />
                        <TextField
                            size={"small"}
                            variant={"standard"}
                            value={nodeInfoDialogState?.ipInfo ? `${nodeInfoDialogState.ipInfo.country_name + ", " + nodeInfoDialogState.ipInfo.city}` : "Unknown"}
                            className={classes.dialogTextField}
                            fullWidth
                            disabled
                            InputProps={{
                                readOnly: true,
                            }}
                            label={"Geo Location"}/>
                        <TextField
                            size={"small"}
                            variant={"standard"}
                            value={isNodeOnline ? (nodeInfoDialogState?.roundTripTimeMs ? `${nodeInfoDialogState.roundTripTimeMs + " ms"}${nodeInfoDialogState.roundTripTimeMs > 1000 ? " - ⚠️ Slow Connection! " : ""}` : "Measuring") : "Offline"}
                            className={classes.dialogTextField}
                            fullWidth
                            disabled
                            InputProps={{
                                readOnly: true,
                            }}
                            label={"Round-trip time"}/>
                    </div>
                </Zoom>

                <Zoom unmountOnExit={true} in={this.state.selectedNodeInfoTab === 1}>
                    <div role="tabpanel" style={{marginTop: "8px"}} hidden={this.state.selectedNodeInfoTab !== 1}>
                        {
                            <DMSPortForwardingRulesView
                                classes={classes}
                                lightMode={true}
                                onLoadDataRequested={this.props.onLoadPortRulesRequested}
                                isLoadingPortRules={this.props.isLoadingPortRules}
                                portRules={this.props.portRules ?? []}
                                logLine={this.props.logLine}
                                displaySnackbar={this.displaySnackbar}
                                dmsClient={this.props.dmsClient}
                                dmsNodes={this.props.dmsNodes}
                                targetNode={nodeInfoDialogState?.targetNode}
                                dmsOnlineNodes={this.props.dmsOnlineNodes}
                                enqueueSnackbar={this.props.enqueueSnackbar}
                                closeSnackbar={this.props.closeSnackbar}/>
                        }
                    </div>
                </Zoom>
                <Zoom unmountOnExit={true} in={this.state.selectedNodeInfoTab === 2}>
                    <div role="tabpanel" style={{marginTop: "8px"}} hidden={this.state.selectedNodeInfoTab !== 2}>
                        {
                            <DMSUserActivityView
                                persistFilters={false}
                                lightMode={true}
                                dmsNodeLogQuery={async query => {
                                    const jwtToken = LocalStorageHelper.getAuthToken();

                                    if (jwtToken === null) {
                                        console.log("missing jwt token");
                                        return {
                                            page: 0,
                                            data: [],
                                            totalCount: 0
                                        };
                                    }

                                    const log = await this.props.dmsRestClient.getNodeLog(jwtToken, nodeInfoTarget.uid, query.page * query.pageSize, query.pageSize);

                                    return {
                                        page: query.page,
                                        data: log,
                                        totalCount: 10000
                                    };
                                }}
                                newLogEvents={0}
                                logLine={this.props.logLine} displaySnackbar={this.displaySnackbar}
                                dmsOnlineNodes={this.props.dmsOnlineNodes} classes={this.props.classes}
                                enqueueSnackbar={this.props.enqueueSnackbar}
                                closeSnackbar={this.props.closeSnackbar}/>
                        }
                    </div>
                </Zoom>
                <Zoom unmountOnExit={true} in={this.state.selectedNodeInfoTab === 3}>
                    <div role="tabpanel" style={{marginTop: "8px"}} hidden={this.state.selectedNodeInfoTab !== 3}>
                        {
                            <DMSNodeHealthView
                                targetNode={this.state.nodeInfoDialogState?.targetNode}
                                dmsNodeBridgeMessagesQuery={async query => {
                                    const jwtToken = LocalStorageHelper.getAuthToken();

                                    if (jwtToken === null) {
                                        console.log("missing jwt token");
                                        return {
                                            page: 0,
                                            data: [],
                                            totalCount: 0
                                        };
                                    }

                                    const bridgeMessages = await this.props.dmsRestClient.getNodeBridgeMessages(jwtToken, nodeInfoTarget.uid, query.page * query.pageSize, query.pageSize);

                                    return {
                                        page: query.page,
                                        data: bridgeMessages,
                                        totalCount: 10000
                                    };
                                }}
                                exportDataRequested={this.onExportBridgeMessagesRequested}
                                classes={classes}
                                logLine={this.props.logLine} displaySnackbar={this.displaySnackbar}
                                enqueueSnackbar={this.props.enqueueSnackbar}
                                closeSnackbar={this.props.closeSnackbar}/>
                        }
                    </div>
                </Zoom>


                <Zoom unmountOnExit={true} in={this.state.selectedNodeInfoTab === 4}>
                    <div role="tabpanel" style={{marginTop: "8px"}}
                         hidden={this.state.selectedNodeInfoTab !== 4}>
                        {
                            nodeInfoDialogState?.isLoadingSoftware ?
                                <LinearProgress
                                    color="secondary"
                                    style={{marginTop: 32}}
                                    variant="query"/> :
                                <div>{
                                    this.renderSoftwareTable(nodeInfoDialogState?.nodeSoftware)
                                }{!isNodeOnline ? <Typography style={{textAlign: "center", margin: 8}}> The node is
                                    offline</Typography> : undefined}</div>
                        }
                    </div>
                </Zoom>
            </div>
        </ModalDialog>);
    };


    renderScreenGrabDialog() {
        const {screenGrabSession} = this.state;

        if (!screenGrabSession) {
            return null;
        }

        return (<ModalDialog
            open={true}
            modalScroll={this.props.targetNodeInfoModeConfig?.targetNode ? "paper" : "body"}
            fullScreen={false}
            maxWidth={"md"}
            disableEscapeKeyDown={false}
            disableBackdropClick={false}
            hideOkButton={true}
            hideCancelButton={true}
            titleComponent={
                <div style={{
                    display: "flex",
                    alignContent: "center",
                    alignItems: "center",
                    justifyContent: "space-between",
                    flex: 1
                }}>
                    <IconButton
                        size={"large"}
                        onClick={() => {
                            this.setState({screenGrabSession: undefined});
                        }}>
                        <CloseIcon/>
                    </IconButton>
                    <Typography variant="h6">{screenGrabSession.targetNode?.uid ?? ""}</Typography>
                    <Slide in={true} direction={"right"} timeout={500}>
                        <IconButton
                            color={"primary"}
                            size={"large"}
                            onClick={() => {
                                this.captureScreenshot(screenGrabSession);
                            }}>
                            <CameraAlt/>
                        </IconButton>
                    </Slide>
                </div>}
            buttonCancelTitle={this.props.targetNodeInfoModeConfig?.targetNode ? "Close" : undefined}
            title={`Capture a screenshot at '${screenGrabSession?.targetNode?.uid}'`}
            buttonOkComponent={<IconButton disabled={screenGrabSession?.isCapturingScreen}
                                           edge="start"
                                           color="primary" onClick={() => {
                this.captureScreenshot(screenGrabSession!);
            }}><CameraAlt/></IconButton>}
            onCancel={() => {
                this.setState({screenGrabSession: undefined});
            }}>
            <div style={{
                display: "flex",
                justifyContent: "center",
            }}>
                <div style={{
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                    alignItems: "center",
                    transition: "all 250ms ease-in-out",
                    pointerEvents: "none",
                    position: screenGrabSession.capturedScreenBase64 ? "absolute" : "relative",
                    left: 0,
                    top: 0,
                    right: 0,
                    bottom: 0,
                    zIndex: 10000,
                    opacity: screenGrabSession?.isCapturingScreen ? 1 : 0
                }}>
                    <CircularProgress style={{margin: 16}}/>
                </div>
                {
                    screenGrabSession?.capturedScreenBase64 ? <img
                        alt={"screenshot"}
                        style={{
                            display: "inline-block",
                            border: "none",
                            maxWidth: "100%",
                            maxHeight: "calc(100vh - 64px)",
                            objectFit: "contain",
                            width: "100%",
                            transition: "filter ease-in-out 500ms",
                            filter: screenGrabSession?.isCapturingScreen ? "grayscale()" : "",
                        }} src={screenGrabSession?.capturedScreenBase64}/> : undefined
                }
            </div>
        </ModalDialog>);
    };

    private saveNodeInfo = async () => {
        const {nodeInfoDialogState} = this.state;

        if (!nodeInfoDialogState?.targetNode) {
            return;
        }

        const jwtToken = LocalStorageHelper.getAuthToken();

        if (jwtToken === null) {
            console.log("missing jwt token");
            return;
        }

        let success = true;

        try {
            this.setState({
                nodeInfoDialogState: {
                    ...this.state.nodeInfoDialogState,
                    isUpdatingNode: true
                }
            });

            await this.props.dmsRestClient.updateNode(jwtToken, nodeInfoDialogState.targetNode);

            this.props.onLoadDataRequested();

            this.displaySnackbar("Updated node: '" + nodeInfoDialogState.targetNode!.uid + "'");
        } catch (e) {
            success = false;
            this.displaySnackbar("Failed to update node: " + e, "error");
        } finally {
            if (success) {
                this.setState({
                    nodeInfoDialogState: undefined
                });
            } else {
                this.setState({
                    nodeInfoDialogState: {
                        ...this.state.nodeInfoDialogState,
                        isUpdatingNode: false
                    }
                });
            }
        }
    };

    private restartSoftwareOnNode = async (nodeManagingSession: IDMSNodeManagingSession) => {
        this.setState(prevState => ({
            nodeManagingSession: {
                ...nodeManagingSession,
                state: DMSNodeManagingSessionState.NODE_RESTARTING_SOFTWARE
            }
        }));

        try {
            const restartMessage = DMSMessageFactory.newMessage<IDMSRestartSoftwareMessage>(DMSMethod.RESTART_NODE_SOFTWARE, {softwareName: nodeManagingSession.selectedSoftware!.name}, nodeManagingSession.targetNode!.uid);
            const restartResult = await this.props.dmsClient.sendMessage<IDMSResult<boolean>>(restartMessage, true);

            if (restartResult?.error) {
                throw restartResult!.error;
            }

            this.setState(prevState => ({
                nodeManagingSession: {
                    ...nodeManagingSession,
                    state: DMSNodeManagingSessionState.NODE_UPDATE_SUCCEEDED
                }
            }));

            this.props.logLine(`successfully restarted ${nodeManagingSession.selectedSoftware!.name} ${nodeManagingSession.targetNode!.uid}`);

            this.displaySnackbar(`Successfully restarted ${nodeManagingSession.selectedSoftware!.name} on node ${nodeManagingSession.targetNode!.uid}`, "success");
        } catch (error: any) {
            this.setState(prevState => ({
                nodeManagingSession: {
                    ...nodeManagingSession,
                    state: DMSNodeManagingSessionState.NODE_UPDATE_FAILED
                }
            }));

            let errorText: string;

            if ("description" in error) {
                errorText = error.description;
            } else {
                errorText = `failed to restart ${nodeManagingSession.selectedSoftware!.name} on node ${nodeManagingSession.targetNode!.uid} Error: ${JSON.stringify(error)}`;
            }

            this.props.logLine(`something went wrong when restarting: ${errorText}`);
            this.displaySnackbar(errorText, "error");
        }
    };

    public restartSoftwareOnNodes = async (nodeManagingSession: IDMSNodeManagingSession) => {
        if (!nodeManagingSession.selectedSoftware || !this.props.currentUser) {
            return;
        }

        this.setState(prevState => ({
            nodeManagingSession: {
                ...nodeManagingSession,
                state: DMSNodeManagingSessionState.NODE_RESTARTING_SOFTWARE
            }
        }));

        try {
            nodeManagingSession.batchTargetNodes?.forEach(n => {
                const restartSoftwareMessage = DMSMessageFactory.newMessage<IDMSRestartSoftwareMessage>(DMSMethod.RESTART_NODE_SOFTWARE, {softwareName: nodeManagingSession.selectedSoftware!.name}, n.uid);

                this.props.dmsClient.sendMessage<IDMSResult<boolean>>(restartSoftwareMessage, false);
            });

            this.setState(prevState => ({
                nodeManagingSession: {
                    ...nodeManagingSession,
                    state: DMSNodeManagingSessionState.NODE_UPDATE_SUCCEEDED
                }
            }), () => {
                this.displaySnackbar(`Restarting '${nodeManagingSession.selectedSoftware!.name}' on ${nodeManagingSession.batchTargetNodes?.length} nodes`, "info");
            });
        } catch (error: any) {
            this.setState(prevState => ({
                nodeManagingSession: {
                    ...nodeManagingSession,
                    state: DMSNodeManagingSessionState.NODE_UPDATE_FAILED
                }
            }));

            let errorText: string;

            if ("description" in error) {
                errorText = error.description;
            } else {
                errorText = `failed to restart ${nodeManagingSession.selectedSoftware.name} on ${nodeManagingSession.batchTargetNodes?.length} nodes Error: ${JSON.stringify(error)}`;
            }

            this.props.logLine(`something went wrong when restarting: ${errorText}`);
            this.displaySnackbar(errorText, "error");
        }
    };

    private updateConfigOnNode = async (nodeManagingSession: IDMSNodeManagingSession) => {
        this.setState(prevState => ({
            nodeManagingSession: {
                ...nodeManagingSession,
                state: DMSNodeManagingSessionState.UPDATING_CONFIG
            }
        }));

        try {
            const updateConfigMessage = DMSMessageFactory.newMessage<IDMSUpdateConfigMessage>(DMSMethod.UPDATE_NODE_CONFIG, {
                softwareName: nodeManagingSession.selectedSoftware!.name,
                config: nodeManagingSession.selectedBitbucketConfig!.contents,
                requestingUser: this.props.currentUser!.username
            }, nodeManagingSession.targetNode!.uid);
            await this.props.dmsClient.sendMessage(updateConfigMessage, true);

            this.setState(prevState => ({
                nodeManagingSession: {
                    ...nodeManagingSession,
                    state: DMSNodeManagingSessionState.NODE_UPDATE_SUCCEEDED
                }
            }));

            this.props.logLine(`successfully updated config ${nodeManagingSession.selectedSoftware!.name} on ${nodeManagingSession.targetNode!.uid}`);
            this.displaySnackbar(`Successfully updated config ${nodeManagingSession.selectedSoftware!.name} on node ${nodeManagingSession.targetNode!.uid}`, "success");
        } catch (error) {
            this.setState(prevState => ({
                nodeManagingSession: {
                    ...nodeManagingSession,
                    state: DMSNodeManagingSessionState.NODE_UPDATE_FAILED
                }
            }));

            this.props.logLine(`something went wrong when updating config: ${error}`);
            this.displaySnackbar(`Could not update config on node ${nodeManagingSession.targetNode!.uid} : ${error}`, "error");
        }
    };

    private getSoftwareConfig = async (softwareName: string, nodeInfoDialogState: INodeInfoDialogState) => {
        const targetNode = nodeInfoDialogState.targetNode!.uid;

        try {
            const getSoftwareConfigMessage = DMSMessageFactory.newMessage<{
                softwareName: string
            }>(DMSMethod.GET_SOFTWARE_CONFIG, {
                softwareName,
            }, targetNode);

            const getConfigResult = await this.props.dmsClient.sendMessage<IDMSResult<string>>(getSoftwareConfigMessage, true);

            if ((getConfigResult?.result ?? "").length < 1) {
                throw new Error("No config available for software " + softwareName);
            } else {
                this.setState(prevState => ({
                    nodeInfoDialogState: {
                        ...prevState.nodeInfoDialogState,
                        softwareConfig: {
                            contents: getConfigResult!.result!,
                            softwareName: softwareName,
                            easyMode: this.props.tableKey === "nodes-dash",
                            easyModeLoading: false
                        },
                    }
                }));
            }
        } catch (e) {
            this.displaySnackbar(`Failed to get config for software: ${softwareName} at node: ${targetNode} Error: ${JSON.stringify(e)}`, "error");
        }
    };

    private updateConfigOnNodes = async (nodeManagingSession: IDMSNodeManagingSession) => {
        if (!nodeManagingSession.batchConfigs || !this.props.currentUser) {
            return;
        }

        const {dmsOnlineNodes} = this.props;

        const isSequentialUpdate = nodeManagingSession.batchUpdateSequentially ?? false;

        if (isSequentialUpdate) {
            nodeManagingSession.batchUpdateProgress = 0;
        }

        this.setState(prevState => ({
            nodeManagingSession: {
                ...nodeManagingSession,
                state: isSequentialUpdate ? DMSNodeManagingSessionState.UPDATING_CONFIG_SEQUENTIALLY : DMSNodeManagingSessionState.UPDATING_CONFIG
            }
        }));

        const forceUpdateDebounced = Util.debounce(() => {
            this.forceUpdate();
        }, 100);

        try {
            this.props.logLine(`Batch updating config ${isSequentialUpdate ? " (sequentially) " : ""} on ${nodeManagingSession.batchTargetNodes?.length} nodes`);

            let pc = 0;

            const batchConfigUpdateHandler = (nodeId: string, updating: boolean, updated: boolean) => {
                const ctx = this.state.nodeManagingSession!.batchUpdateContext![nodeId];

                if (!ctx) {
                    return;
                }

                ctx.updating = updating;
                ctx.updated = updated;

                let hasCompleted = false;

                if (!updated) {
                    const progress = 100 / (this.state.nodeManagingSession!.batchTargetNodes ?? []).length * ++pc;

                    if (progress === 100) {
                        hasCompleted = true;
                    }

                    this.state.nodeManagingSession!.batchUpdateProgress = progress;
                }

                if (!updated && hasCompleted) {
                    this.forceUpdate();
                } else {
                    forceUpdateDebounced();
                }
            };

            for (const key in nodeManagingSession.batchTargetNodes) {
                const n = nodeManagingSession.batchTargetNodes[key];

                const ctx = nodeManagingSession.batchUpdateContext ?? {};

                const ctxNode = ctx[n.uid];

                try {
                    const isNodeOnline = dmsOnlineNodes && dmsOnlineNodes[n.uid] !== undefined;

                    if (!isNodeOnline) {
                        if (isSequentialUpdate) {
                            batchConfigUpdateHandler(n.uid, false, false);
                        }
                        continue;
                    }

                    if (isSequentialUpdate) {
                        batchConfigUpdateHandler(n.uid, true, false);
                    }

                    const targetConfig = nodeManagingSession.batchConfigs.find(value => {
                        return value.node === n.uid;
                    });

                    if (!targetConfig) {
                        throw new Error("No config available for node " + n.uid);
                    }

                    const updateConfigMessage = DMSMessageFactory.newMessage<IDMSUpdateConfigMessage>(DMSMethod.UPDATE_NODE_CONFIG, {
                        softwareName: nodeManagingSession.selectedSoftware!.name,
                        config: targetConfig!.contents,
                        requestingUser: this.props.currentUser!.username
                    }, n.uid);

                    const updateConfigPromise = this.props.dmsClient.sendMessage<IDMSResult<boolean>>(updateConfigMessage, isSequentialUpdate);

                    if (isSequentialUpdate && ctxNode) {
                        const updateResult = await updateConfigPromise;

                        if (updateResult && updateResult!.result === true) {
                            batchConfigUpdateHandler(n.uid, false, true);
                        } else {
                            batchConfigUpdateHandler(n.uid, false, false);

                            throw new Error(`Failed to update node ${n.uid}`);
                        }
                    } else {
                        console.warn(n.uid + " is missing from the context");
                    }
                } catch (err) {
                    debugger;
                    this.displaySnackbar(n.uid + " failed to update, aborting sequential batch update: " + JSON.stringify(err), "error");
                    this.props.logLine(n.uid + " failed to update, aborting sequential batch update: " + JSON.stringify(err));
                    break;
                }
            }

            if (isSequentialUpdate) {
                await Util.sleep(500);
            }

            this.setState(prevState => ({
                nodeManagingSession: {
                    ...nodeManagingSession,
                    state: DMSNodeManagingSessionState.NODE_UPDATE_SUCCEEDED
                }
            }), () => {
                if (isSequentialUpdate) {
                    this.displaySnackbar(`Updated config on ${nodeManagingSession.batchTargetNodes?.length} nodes`, "info");
                } else {
                    this.displaySnackbar(`Updating config on ${nodeManagingSession.batchTargetNodes?.length} nodes`, "info");
                }
            });
        } catch (error: any) {
            this.setState(prevState => ({
                nodeManagingSession: {
                    ...nodeManagingSession,
                    state: DMSNodeManagingSessionState.NODE_UPDATE_FAILED
                }
            }));

            let errorText: string;

            if ("description" in error) {
                errorText = error.description;
            } else {
                errorText = `failed to update config on nodes Error: ${JSON.stringify(error)}`;
            }

            this.props.logLine(`something went wrong when updating: ${errorText}`);
            this.displaySnackbar(errorText, "error");
        }
    };

    private getConfigsForLocationFromBitbucket = async (nodeManagingSession: IDMSNodeManagingSession) => {
        const jwtToken = LocalStorageHelper.getAuthToken();

        if (jwtToken === null) {
            console.log("missing jwt token");
            return;
        }
        try {
            let targetLocation: string | undefined;

            const selectedLocations = (nodeManagingSession?.batchTargetNodes ?? []).map(value => {
                return value.location;
            });
            const uniqueLocations = Util.getArrayUniqueItems(selectedLocations);

            if (uniqueLocations.length > 1) {
                const err = Error("Batch update is only possible for nodes in a single location");
                err["clientSide"] = true;
                throw err;
            }

            targetLocation = uniqueLocations[0];

            this.setState(prevState => ({
                nodeManagingSession: {
                    ...nodeManagingSession,
                    state: DMSNodeManagingSessionState.BATCH_GETTING_LATEST_CONFIG_FROM_BITBUCKET
                }
            }));

            const configs = await this.props.dmsRestClient.getBitbucketConfigs(jwtToken, targetLocation!, nodeManagingSession!.selectedSoftware!.name);

            this.setState(prevState => ({
                nodeManagingSession: {
                    ...nodeManagingSession,
                    batchConfigs: configs,
                    state: DMSNodeManagingSessionState.BATCH_GOT_CONFIG_FROM_BITBUCKET,
                }
            }));
        } catch (error: any) {
            this.setState(prevState => ({
                nodeManagingSession: {
                    ...nodeManagingSession,
                    state: DMSNodeManagingSessionState.NODE_UPDATE_FAILED
                }
            }));

            if (error["clientSide"]) {
                this.displaySnackbar(`${error}`, "error");
            } else {
                this.displaySnackbar(`Could not get the config for location from Bitbucket, please make sure the config file exists!\r\n ${error}`, "error");
            }
        }
    };

    private getNodeConfigFromBitbucket = async (nodeManagingSession: IDMSNodeManagingSession) => {
        const jwtToken = LocalStorageHelper.getAuthToken();

        if (jwtToken === null) {
            console.log("missing jwt token");
            return;
        }

        const shouldGetConfigFromBitbucket = nodeManagingSession.selectedSoftware?.name !== "dms_client";

        try {
            const location = nodeManagingSession.targetNode!.location;
            const nodeName = nodeManagingSession.targetNode!.uid;

            let nodeConfig: IBitbucketConfig;
            let clientConfig: IDMSClientConfig | undefined;

            if (shouldGetConfigFromBitbucket) {
                this.setState(prevState => ({
                    nodeManagingSession: {
                        ...nodeManagingSession,
                        state: DMSNodeManagingSessionState.GETTING_LATEST_CONFIG_FROM_BITBUCKET
                    }
                }));

                nodeConfig = await this.props.dmsRestClient.getBitbucketConfig(jwtToken, nodeName, location, nodeManagingSession!.selectedSoftware!.name);
            } else {
                let twoFactorToken = "";

                try {
                    twoFactorToken = await this.getTwoFactorTokenPromise(`Getting the config from '${nodeManagingSession.copyFromNode!.uid}' requires two-factor verification`);
                } catch (e) {
                    return;
                }

                this.props.cancelTwoFactorRequest();

                this.setState({
                    nodeManagingSession: {
                        ...nodeManagingSession,
                        state: DMSNodeManagingSessionState.GETTING_LATEST_CONFIG_FROM_BITBUCKET
                    }
                });

                clientConfig = await this.props.dmsRestClient.getNodeConfig(jwtToken, twoFactorToken, nodeManagingSession.copyFromNode!.uid);

                nodeConfig = {
                    node: nodeManagingSession.copyFromNode!.uid,
                    author: nodeManagingSession.copyFromNode!.createdBy!,
                    hash: "",
                    contents: ""
                };
            }

            const getSoftwareConfigMessage = DMSMessageFactory.newMessage<{
                softwareName: string
            }>(DMSMethod.GET_SOFTWARE_CONFIG, {
                softwareName: nodeManagingSession.selectedSoftware!.name,
            }, nodeManagingSession.targetNode!.uid);

            const getConfigResult = await this.props.dmsClient.sendMessage<IDMSResult<string>>(getSoftwareConfigMessage, true);
            let oldConfig: string | undefined;

            if ((getConfigResult?.result ?? "").length < 1) {
                // no config
            } else {
                oldConfig = getConfigResult!.result;
            }

            if (clientConfig) {
                // updating dms client config
                const parsedConfig = JSON.parse(oldConfig!) as IDMSClientConfig;
                parsedConfig.jwtToken = clientConfig.jwtToken;
                parsedConfig.nodeName = clientConfig.nodeName;

                nodeConfig.contents = JSON.stringify(parsedConfig, null, 4);
            }

            this.setState(prevState => ({
                nodeManagingSession: {
                    ...nodeManagingSession,
                    state: DMSNodeManagingSessionState.GOT_CONFIG_FROM_BITBUCKET,
                    selectedBitbucketConfig: nodeConfig,
                    oldConfig
                }
            }));
        } catch (error: any) {
            this.setState(prevState => ({
                nodeManagingSession: {
                    ...nodeManagingSession,
                    state: DMSNodeManagingSessionState.NODE_UPDATE_FAILED
                }
            }));

            if (shouldGetConfigFromBitbucket) {
                this.displaySnackbar(`Could not get the config for node ${nodeManagingSession.targetNode!.uid} from Bitbucket, please make sure the config file exists!\r\n ${error}`, "error");
            } else {
                this.displaySnackbar(`Failed to get the config from node. \r\n ${error}`, "error");
            }
        }
    };

    private captureScreenshot = async (screenGrabSession: IScreenGrabSession) => {
        try {
            if (!screenGrabSession) {
                return;
            }

            this.setState(prevState => ({
                screenGrabSession: {
                    ...screenGrabSession,
                    isCapturingScreen: true
                },
            }));

            const screenGrabMessage = DMSMessageFactory.newMessage<void>(DMSMethod.TAKE_DESKTOP_SCREENSHOT, undefined, screenGrabSession.targetNode!.uid);
            const screenGrabResult = await this.props.dmsClient.sendMessage<IDMSResult<Buffer>>(screenGrabMessage, true);

            if (screenGrabResult?.error) {
                throw screenGrabResult!.error;
            }

            const screenBuffer = new Buffer(screenGrabResult!.result!);

            if (this.state.screenGrabSession) {
                this.setState(prevState => ({
                    screenGrabSession: {
                        ...prevState.screenGrabSession!,
                        capturedScreenBase64: 'data:image/jpeg;base64,' + screenBuffer.toString('base64')
                    }
                }));
            }
        } catch (error: any) {
            let errorText: string;

            if ("description" in error) {
                errorText = error.description;
            } else {
                errorText = `failed to capture screen on node ${screenGrabSession?.targetNode?.uid} Error: ${JSON.stringify(error)}`;
            }

            this.props.logLine(`something went wrong when capturing the screen: ${errorText}`);
            this.displaySnackbar(errorText, "error");
        } finally {
            if (this.state.screenGrabSession) {
                this.setState(prevState => ({
                    screenGrabSession: {
                        ...prevState.screenGrabSession!,
                        isCapturingScreen: false
                    }
                }));
            }
        }
    };

    private listNodeLogs = async (nodeManagingSession: IDMSNodeManagingSession) => {
        this.setState(prevState => ({
            nodeManagingSession: {
                ...nodeManagingSession,
                state: DMSNodeManagingSessionState.LISTING_NODE_LOGS
            }
        }));

        try {
            const listNodeLogsMessage = DMSMessageFactory.newMessage<IDMSGetSoftwareLogsMessage>(DMSMethod.LIST_NODE_LOGS, {
                softwareName: nodeManagingSession.selectedSoftware!.name,
            }, nodeManagingSession.targetNode!.uid);
            const result = await this.props.dmsClient.sendMessage<IDMSResult<IDMSLogFile[]>>(listNodeLogsMessage, true);

            if (result?.error) {
                throw result!.error;
            }

            if (!result?.result || result?.result.length < 1) {
                throw new Error("No logs available");
            }

            result.result.forEach(r => {
                if (r.dateModified) {
                    //recreate the date object
                    r.dateModified = new Date(r.dateModified);
                }
            });

            this.setState(prevState => ({
                nodeManagingSession: {
                    ...nodeManagingSession,
                    state: DMSNodeManagingSessionState.LISTED_NODE_LOGS,
                    nodeLogsList: result!.result
                }
            }));
        } catch (error: any) {
            this.setState(prevState => ({
                nodeManagingSession: {
                    ...nodeManagingSession,
                    state: DMSNodeManagingSessionState.NODE_UPDATE_FAILED
                }
            }));

            let errorText: string;

            if ("description" in error) {
                errorText = error.description;
            } else {
                errorText = `Could not get logs from node ${nodeManagingSession.targetNode!.uid} : ${JSON.stringify(error)}`;
            }

            this.props.logLine(`something went wrong when listing logs: ${errorText}`);
            this.displaySnackbar(errorText, "error");
        }
    };

    private getNodeBridgeActions = async (nodeManagingSession: IDMSNodeManagingSession) => {
        this.setState(prevState => ({
            nodeManagingSession: {
                ...nodeManagingSession,
                state: DMSNodeManagingSessionState.LISTING_NODE_BRIDGE_ACTIONS
            }
        }));

        try {
            const listNodeBridgeActions = DMSMessageFactory.newMessage<IDMSListBridgeActions>(DMSMethod.LIST_BRIDGE_ACTIONS, {
                softwareName: nodeManagingSession.selectedSoftware!.name,
            }, nodeManagingSession.targetNode!.uid);
            const result = await this.props.dmsClient.sendMessage<IDMSResult<IDMSListBridgeActionsResult>>(listNodeBridgeActions, true);

            if (result?.error) {
                throw result!.error;
            }

            if (!result?.result || result?.result.actions?.length < 1) {
                throw new Error("No bridge actions available");
            }

            this.setState(prevState => ({
                nodeManagingSession: {
                    ...nodeManagingSession,
                    state: DMSNodeManagingSessionState.LISTED_NODE_BRIDGE_ACTIONS,
                    nodeBridgeActions: result?.result?.actions ?? []
                }
            }));
        } catch (error: any) {
            this.setState(prevState => ({
                nodeManagingSession: {
                    ...nodeManagingSession,
                    state: DMSNodeManagingSessionState.NODE_UPDATE_FAILED
                }
            }));

            let errorText: string;

            if ("description" in error) {
                errorText = error.description;
            } else {
                errorText = `Could not get bridge actions for software ${nodeManagingSession.selectedSoftware!.name} from node ${nodeManagingSession.targetNode!.uid} : ${JSON.stringify(error)}`;
            }

            this.props.logLine(`something went wrong when listing bridge actions: ${errorText}`);
            this.displaySnackbar(errorText, "error");
        }
    };

    private getNodeLog = async (nodeManagingSession: IDMSNodeManagingSession, logName: string) => {
        this.setState(prevState => ({
            nodeManagingSession: {
                ...nodeManagingSession,
                state: DMSNodeManagingSessionState.GETTING_NODE_LOG
            }
        }));

        try {
            const getNodeLogMessage = DMSMessageFactory.newMessage<IDMSGetSoftwareLogsMessage>(DMSMethod.GET_NODE_LOG, {
                softwareName: nodeManagingSession.selectedSoftware!.name,
                logName
            }, nodeManagingSession.targetNode!.uid);
            const result = await this.props.dmsClient.sendMessage<IDMSResult<IDMSLogFile>>(getNodeLogMessage, true);

            if (!result?.result) {
                throw new Error("No log available");
            }

            this.setState(prevState => ({
                nodeManagingSession: {
                    ...nodeManagingSession,
                    state: DMSNodeManagingSessionState.GOT_NODE_LOG,
                    nodeLog: result!.result
                }
            }));
        } catch (error) {
            this.setState(prevState => ({
                nodeManagingSession: {
                    ...nodeManagingSession,
                    state: DMSNodeManagingSessionState.NODE_UPDATE_FAILED
                }
            }));

            this.props.logLine(`something went wrong when getting log: ${error}`);
            this.displaySnackbar(`Could not get log from node ${nodeManagingSession.targetNode!.uid} : ${error}`, "error");
        }
    };

    private onBridgeActionSelected = (action: IBBCBridgeAction) => {
        const {nodeManagingSession} = this.state;

        action.payload = {
            Id: action.Id
        };

        action.parameters?.forEach(p => {
            if (p.defaultValue) {
                action.payload![p.name] = p.defaultValue;
            }
        });

        if (action?.parameters && action.parameters.length > 0) {
            this.setState(prevState => ({
                nodeManagingSession: {
                    ...nodeManagingSession!,
                    state: DMSNodeManagingSessionState.CONFIGURE_NODE_BRIDGE_ACTION,
                    selectedAction: action
                }
            }));
        } else {
            this.executeBridgeAction(action);
        }
    };

    private executeBridgeAction = async (action: IBBCBridgeAction) => {
        const {nodeManagingSession} = this.state;

        this.setState(prevState => ({
            nodeManagingSession: {
                ...nodeManagingSession!,
                state: DMSNodeManagingSessionState.EXECUTING_NODE_BRIDGE_ACTION,
                selectedAction: action
            }
        }));

        try {
            const msg = DMSMessageFactory.newMessage<IDMSExecuteBridgeAction>(DMSMethod.EXECUTE_BRIDGE_ACTION, {
                action
            }, nodeManagingSession!.targetNode!.uid);
            const result = await this.props.dmsClient.sendMessage<IDMSResult<IDMSExecuteBridgeActionResult>>(msg, true);

            if (result?.error) {
                throw result!.error;
            }

            if (!result?.result || result?.result.success !== true) {
                throw new Error("failed to execute action: " + action.Id + " on bridge: " + action.bridgeType);
            }

            if (result?.result?.payload) {
                this.setState(prevState => ({
                    nodeManagingSession: {
                        ...nodeManagingSession!,
                        state: DMSNodeManagingSessionState.EXECUTED_NODE_BRIDGE_ACTION_WITH_RESULT,
                        selectedAction: action,
                        actionResult: result.result
                    }
                }));
            } else {
                this.displaySnackbar("Executed bridge action: " + action.Id + " on " + nodeManagingSession!.targetNode!.uid, "info");

                this.setState({
                    nodeManagingSession: undefined
                });
            }
        } catch (error: any) {
            this.setState(prevState => ({
                nodeManagingSession: undefined
            }));

            let errorText: string;

            if ("description" in error) {
                errorText = error.description;
            } else {
                errorText = `Could not execute bridge action : ${JSON.stringify(error)}`;
            }

            this.props.logLine(`something went wrong when executing bridge action: ${errorText}`);
            this.displaySnackbar(errorText, "error");
        }
    };

    private onDeleteNodeRequested = async (node: IDBNode) => {
        const jwtToken = LocalStorageHelper.getAuthToken();

        if (jwtToken === null) {
            console.log("missing jwt token");
            return;
        }

        this.setState({isDeletingNodeInProgress: true});

        try {
            let twoFactorToken = "";

            try {
                twoFactorToken = await this.getTwoFactorTokenPromise("Deleting a node requires two-factor verification");
            } catch (e) {
                this.setState({
                    isCreatingNodeInProgress: false
                });

                return;
            }

            this.props.cancelTwoFactorRequest();

            await this.props.dmsRestClient.deleteNode(jwtToken, twoFactorToken, node.uid);
            await this.props.onLoadDataRequested();

            this.setState({
                isDeletingNodeInProgress: false,
                deletionTargetNode: undefined,
                deletionTargetNodeNameConfirmation: undefined
            });
            this.displaySnackbar(`Deleted node ${node.uid}`, "info");
        } catch (error) {
            this.setState({
                isDeletingNodeInProgress: false,
                deletionTargetNode: undefined,
                deletionTargetNodeNameConfirmation: undefined
            });

            this.props.cancelTwoFactorRequest();

            this.displaySnackbar(`Could not delete node: ${node.uid}: ${error}`, "error");
        }
    };

    private onGetNodeConfigRequested = async (node: IDBNode) => {
        const jwtToken = LocalStorageHelper.getAuthToken();

        if (jwtToken === null) {
            console.log("missing jwt token");
            return;
        }

        try {
            let twoFactorToken = "";

            try {
                twoFactorToken = await this.getTwoFactorTokenPromise("Viewing the config of a node requires two-factor verification");
            } catch (e) {
                return;
            }

            this.props.cancelTwoFactorRequest();

            this.setState({isGettingNodeConfigInProgress: true, getConfigTargetNode: node});

            const nodeConfig = await this.props.dmsRestClient.getNodeConfig(jwtToken, twoFactorToken, node.uid);

            this.setState({
                isGettingNodeConfigInProgress: false,
                nodeConfig: nodeConfig
            });
        } catch (error) {
            this.setState({
                isGettingNodeConfigInProgress: false,
                getConfigTargetNode: undefined
            });

            this.props.cancelTwoFactorRequest();

            this.displaySnackbar(`Could not get config for node: ${node.uid}: ${error}`, "error");
        }
    };

    private onCreateNodeRequested = async (newNodeRequest: INewNodeRequest) => {
        if (!newNodeRequest.validates) {
            return;
        }

        const jwtToken = LocalStorageHelper.getAuthToken();

        if (jwtToken === null) {
            console.log("missing jwt token");
            return;
        }

        this.setState({isCreatingNodeInProgress: true});

        try {
            let twoFactorToken = "";

            try {
                twoFactorToken = await this.getTwoFactorTokenPromise("Registering a node requires two-factor verification");
            } catch (e) {
                this.setState({
                    isCreatingNodeInProgress: false
                });

                return;
            }

            this.props.cancelTwoFactorRequest();

            newNodeRequest.createdToken = await this.props.dmsRestClient.registerNode(jwtToken, twoFactorToken, newNodeRequest.nodeName!.toLowerCase(), newNodeRequest.location!);

            await this.props.onLoadDataRequested();

            this.setState({isCreatingNodeInProgress: false});
            this.displaySnackbar(`Registered node ${newNodeRequest.nodeName}`, "info");
        } catch (error: any) {
            let errorText: string;

            if (error.response) {
                errorText = `${error.response.status}:${error.response.statusText}`;
            } else {
                errorText = error.toString();
            }

            this.props.cancelTwoFactorRequest();

            this.setState({isCreatingNodeInProgress: false, newNodeRequest: undefined});
            this.displaySnackbar(`Could not register node: ${newNodeRequest.nodeName}: ${errorText}`, "error");
        }
    };

    public updateSoftwareOnNodes = async (nodeManagingSession: IDMSNodeManagingSession, updatePayload: IDMSFile) => {
        if (!nodeManagingSession.selectedSoftware || !this.props.currentUser) {
            return;
        }

        const {dmsOnlineNodes} = this.props;

        const isSequentialUpdate = nodeManagingSession.batchUpdateSequentially ?? false;

        if (isSequentialUpdate) {
            nodeManagingSession.batchUpdateProgress = 0;
        }

        this.setState(prevState => ({
            nodeManagingSession: {
                ...nodeManagingSession,
                state: isSequentialUpdate ? DMSNodeManagingSessionState.UPDATING_SOFTWARE_SEQUENTIALLY : DMSNodeManagingSessionState.UPDATING_SOFTWARE
            }
        }));

        const forceUpdateDebounced = Util.debounce(() => {
            this.forceUpdate();
        }, 100);

        try {
            this.props.logLine(`Batch updating software ${isSequentialUpdate ? " (sequentially) " : ""}${nodeManagingSession.selectedSoftware.name} on ${nodeManagingSession.batchTargetNodes?.length} nodes`);

            let pc = 0;

            const batchSoftwareUpdateHandler = (nodeId: string, updating: boolean, updated: boolean) => {
                const ctx = this.state.nodeManagingSession!.batchUpdateContext![nodeId];

                if (!ctx) {
                    return;
                }

                ctx.updating = updating;
                ctx.updated = updated;

                let hasCompleted = false;

                if (!updated) {
                    const progress = 100 / (this.state.nodeManagingSession!.batchTargetNodes ?? []).length * ++pc;

                    if (progress === 100) {
                        hasCompleted = true;
                    }

                    this.state.nodeManagingSession!.batchUpdateProgress = progress;
                }

                if (!updated && hasCompleted) {
                    this.forceUpdate();
                } else {
                    forceUpdateDebounced();
                }
            };

            for (const key in nodeManagingSession.batchTargetNodes) {
                if (!nodeManagingSession.batchTargetNodes.hasOwnProperty(key)) {
                    continue;
                }

                const n = nodeManagingSession.batchTargetNodes[key];

                const ctx = nodeManagingSession.batchUpdateContext ?? {};

                const ctxNode = ctx[n.uid];

                try {
                    const isNodeOnline = dmsOnlineNodes && dmsOnlineNodes[n.uid] !== undefined;

                    if (!isNodeOnline) {
                        if (isSequentialUpdate) {
                            batchSoftwareUpdateHandler(n.uid, false, false);
                        }
                        continue;
                    }

                    if (isSequentialUpdate) {
                        batchSoftwareUpdateHandler(n.uid, true, false);
                    }

                    const updateSoftwareMessage = DMSMessageFactory.newMessage<IDMSUpdateSoftwareMessage>(DMSMethod.UPDATE_NODE_SOFTWARE, {
                        softwareName: nodeManagingSession.selectedSoftware!.name,
                        downloadUrl: updatePayload.publicLink,
                        requestingUser: this.props.currentUser!.username,
                        ignoreVersionCheck: nodeManagingSession.ignoreSoftwareVersion ?? false
                    }, n.uid);

                    const updateSoftwarePromise = this.props.dmsClient.sendMessage<IDMSResult<boolean>>(updateSoftwareMessage, isSequentialUpdate);

                    if (isSequentialUpdate && ctxNode) {
                        const updateResult = await updateSoftwarePromise;

                        if (updateResult && updateResult!.result === true) {
                            batchSoftwareUpdateHandler(n.uid, false, true);
                        } else {
                            batchSoftwareUpdateHandler(n.uid, false, false);

                            throw new Error(`Failed to update node ${n.uid}`);
                        }
                    } else {
                        console.warn(n.uid + " is missing from the context");
                    }
                } catch (err) {
                    debugger;
                    this.displaySnackbar(n.uid + " failed to update, aborting sequential batch update: " + JSON.stringify(err), "error");
                    this.props.logLine(n.uid + " failed to update, aborting sequential batch update: " + JSON.stringify(err));
                    break;
                }
            }

            if (isSequentialUpdate) {
                await Util.sleep(500);
            }

            this.setState(prevState => ({
                nodeManagingSession: {
                    ...nodeManagingSession,
                    state: DMSNodeManagingSessionState.NODE_UPDATE_SUCCEEDED
                }
            }), () => {
                if (isSequentialUpdate) {
                    this.displaySnackbar(`Updated '${nodeManagingSession.selectedSoftware!.name}' on ${nodeManagingSession.batchTargetNodes?.length} nodes`, "info");
                } else {
                    this.displaySnackbar(`Updating '${nodeManagingSession.selectedSoftware!.name}' on ${nodeManagingSession.batchTargetNodes?.length} nodes`, "info");
                }
            });
        } catch (error: any) {
            this.setState(prevState => ({
                nodeManagingSession: {
                    ...nodeManagingSession,
                    state: DMSNodeManagingSessionState.NODE_UPDATE_FAILED
                }
            }));

            let errorText: string;

            if ("description" in error) {
                errorText = error.description;
            } else {
                errorText = `failed to update ${nodeManagingSession.selectedSoftware.name} on node ${nodeManagingSession.targetNode!.uid} Error: ${JSON.stringify(error)}`;
            }

            this.props.logLine(`something went wrong when updating: ${errorText}`);
            this.displaySnackbar(errorText, "error");
        }
    };

    public updateSoftwareOnNode = async (nodeManagingSession: IDMSNodeManagingSession, updatePayload: IDMSFile) => {
        if (!nodeManagingSession.selectedSoftware || !this.props.currentUser) {
            return;
        }

        this.setState(prevState => ({
            nodeManagingSession: {
                ...nodeManagingSession,
                state: DMSNodeManagingSessionState.UPDATING_SOFTWARE
            }
        }));

        this.props.logLine(`updating software '${nodeManagingSession.selectedSoftware.name}' on '${nodeManagingSession.targetNode!.uid}'`);

        try {
            const updateSoftwareMessage = DMSMessageFactory.newMessage<IDMSUpdateSoftwareMessage>(DMSMethod.UPDATE_NODE_SOFTWARE, {
                softwareName: nodeManagingSession.selectedSoftware.name,
                downloadUrl: updatePayload.publicLink,
                requestingUser: this.props.currentUser.username,
                ignoreVersionCheck: nodeManagingSession.ignoreSoftwareVersion ?? false
            }, nodeManagingSession.targetNode!.uid);

            const updateResult = await this.props.dmsClient.sendMessage<IDMSResult<boolean>>(updateSoftwareMessage, true);

            if (updateResult?.error) {
                throw updateResult!.error;
            }

            this.props.logLine(`successfully updated '${nodeManagingSession.selectedSoftware.name}' on '${nodeManagingSession.targetNode!.uid}'`);

            this.setState(prevState => ({
                nodeManagingSession: {
                    ...nodeManagingSession,
                    state: DMSNodeManagingSessionState.NODE_UPDATE_SUCCEEDED
                }
            }));

            this.displaySnackbar(`Successfully updated ${nodeManagingSession.selectedSoftware!.name} on node ${nodeManagingSession.targetNode!.uid}`, "success");
        } catch (error: any) {
            this.setState(prevState => ({
                nodeManagingSession: {
                    ...nodeManagingSession,
                    state: DMSNodeManagingSessionState.NODE_UPDATE_FAILED
                }
            }));

            let errorText: string;

            if ("description" in error) {
                errorText = error.description;
            } else {
                errorText = `failed to update ${nodeManagingSession.selectedSoftware.name} on node ${nodeManagingSession.targetNode!.uid} Error: ${JSON.stringify(error)}`;
            }

            this.props.logLine(`something went wrong when updating: ${errorText}`);
            this.displaySnackbar(errorText, "error");
        }
    };

    private onExportBridgeMessagesRequested = async (fromDate: Date, toDate: Date, offset: number, limit: number): Promise<IBBCBridgeMessage[]> => {
        const targetNode = this.state.nodeInfoDialogState?.targetNode;

        if (!targetNode) {
            return [];
        }

        const {dmsRestClient} = this.props;
        const jwtToken = LocalStorageHelper.getAuthToken();

        return await dmsRestClient.getNodeBridgeMessagesExtended(jwtToken!, targetNode.uid, fromDate, toDate, limit, offset);
    };

    private validateNewNodeRequest = Util.debounce((newNodeRequest: INewNodeRequest) => {
        let isValid: boolean;

        const req = newNodeRequest;

        if (!req) {
            isValid = false;
        } else {
            isValid = ValidationHelper.validateNode(req.nodeName, req.location);
        }

        this.setState(prevState => ({
            newNodeRequest: {
                ...prevState.newNodeRequest,
                validates: isValid
            }
        }));

    }, 500);

    public static getNodeStateIcon = (state?: DMSNodeState) => {
        let nodeStateIcon: any;

        switch (state) {
            case DMSNodeState.NODE_STATE_IDLE:
                nodeStateIcon = <CheckCircleIcon/>;
                break;
            case DMSNodeState.NODE_STATE_CONFIG_UPDATE_STARTED:
            case DMSNodeState.NODE_STATE_SOFTWARE_UPDATE_STARTED:
                nodeStateIcon = <PlayCircleFilledWhiteIcon/>;
                break;
            case DMSNodeState.NODE_STATE_CONFIG_UPDATE_SUCCEEDED:
            case DMSNodeState.NODE_STATE_SOFTWARE_UPDATE_SUCCEEDED:
                nodeStateIcon = <CheckCircleIcon/>;
                break;
            case DMSNodeState.NODE_STATE_CONFIG_UPDATE_FAILED:
            case DMSNodeState.NODE_STATE_SOFTWARE_UPDATE_FAILED:
                nodeStateIcon = <ErrorIcon/>;
                break;
            case DMSNodeState.NODE_STATE_OFFLINE:
                nodeStateIcon = <SignalCellularConnectedNoInternet0BarTwoToneIcon/>;
                break;
            default:
                nodeStateIcon = <HelpIcon/>;
                break;
        }

        return nodeStateIcon;
    };

    private onShowNodeInfoRequested = async (node: IDBNode, selectedNodeInfoTab: number | undefined = undefined, targetSoftwareConfig?: string) => {
        if (!node) {
            return;
        }

        const connectedNode = this.props.dmsOnlineNodes[node.uid];

        const nodeInfoDialogState: INodeInfoDialogState = {
            targetNode: node
        };

        this.setState({nodeInfoDialogState});

        const jwtToken = LocalStorageHelper.getAuthToken();

        if (jwtToken === null) {
            console.log("missing jwt token");
            return;
        }

        if (!connectedNode) {
            return;
        }

        IPInfo.getIPV4Info(node.lastIp).then(info => {
            this.setState(prevState => ({
                nodeInfoDialogState: {
                    ...prevState.nodeInfoDialogState,
                    ipInfo: info,
                },
            }));
        }).catch(e => {
            //ignored
        });

        try {
            this.setState(prevState => ({
                nodeInfoDialogState: {
                    ...prevState.nodeInfoDialogState,
                    isLoadingSoftware: true
                },
                selectedNodeInfoTab: selectedNodeInfoTab ?? 0
            }));

            const getNodeSoftwareMessage = DMSMessageFactory.newMessage<void>(DMSMethod.GET_NODE_SOFTWARE, undefined, connectedNode.uid);
            const getSoftResult = await this.props.dmsClient.sendMessage<IDMSResult<IDMSSoftwareBundle[]>>(getNodeSoftwareMessage, true);

            const nodeSoftware = getSoftResult?.result ?? [];

            this.setState(prevState => ({
                nodeInfoDialogState: {
                    ...prevState.nodeInfoDialogState,
                    nodeSoftware,
                    isLoadingSoftware: false,
                }
            }), async () => {
                if (targetSoftwareConfig) {
                    await this.getSoftwareConfig(targetSoftwareConfig, nodeInfoDialogState!);
                }

                const executeHealthCheckMessage = DMSMessageFactory.newMessage<IDMSResult<IDMSHealthCheckResult[]>>(DMSMethod.EXECUTE_HEALTH_CHECK, undefined, connectedNode.uid);
                this.props.dmsClient.sendMessage<IDMSResult<IDMSHealthCheckResult[]>>(executeHealthCheckMessage, true).then(healthCheckResult => {
                    this.setState(prevState => ({
                        nodeInfoDialogState: {
                            ...prevState.nodeInfoDialogState,
                            healthCheckResult: healthCheckResult?.result
                        }
                    }));
                }).catch(e => {

                });

                const start = new Date();

                const pingMessage = DMSMessageFactory.newMessage<void>(DMSMethod.PING, undefined, connectedNode.uid);
                this.props.dmsClient.sendMessage<IDMSResult<boolean>>(pingMessage, true).then(pingResult => {
                    const roundTripTimeMs = new Date().getTime() - start.getTime();

                    this.setState(prevState => ({
                        nodeInfoDialogState: {
                            ...prevState.nodeInfoDialogState,
                            roundTripTimeMs: roundTripTimeMs
                        }
                    }));
                });
            });
        } catch (error) {
            this.displaySnackbar(`Could not get the installed software: ${JSON.stringify(error)}`, "error");
            this.setState(prevState => ({
                nodeInfoDialogState: {
                    isLoadingSoftware: false
                }
            }));
        }
    };

    private onManageNodesRequested = async (nodes: IDBNode[], mode: "batch-software" | "batch-restart" | "batch-config") => {
        switch (mode) {
            case "batch-restart":
            case "batch-software":
            case "batch-config":
                break;
            default:
                throw new Error(`Unsupported mode ${mode}`);
        }

        try {
            const getSoftwarePromises = new Array<Promise<IDMSResult<IDMSSoftwareBundle[]> | undefined>>();

            const nodeManagingSession: IDMSNodeManagingSession = {
                sessionMode: mode,
                batchTargetNodes: nodes,
                state: DMSNodeManagingSessionState.GETTING_NODE_SOFTWARE,
                batchUpdateContext: {},
                batchUpdateProgress: 0
            };

            let availableSoftware = new Array<IDMSSoftwareBundle>();

            for (let i = 0; i < nodes.length; i++) {
                let node = nodes[i];

                // initialize the result array
                nodeManagingSession.batchUpdateContext![node.uid] = {};
            }

            this.setState({
                nodeManagingSession
            });

            const forceUpdateDebounced = Util.debounce(() => {
                this.forceUpdate();
            }, 100);

            let pc = 0;

            const batchSoftwareQueryHandler = (nodeId: string, result: IDMSSoftwareBundle[] | undefined, offline: boolean) => {
                const progress = 100 / nodes.length * ++pc;
                this.state.nodeManagingSession!.batchUpdateContext![nodeId].software = result;
                this.state.nodeManagingSession!.batchUpdateContext![nodeId].offline = offline;
                this.state.nodeManagingSession!.batchUpdateProgress = progress;

                if (progress === 100) {
                    this.forceUpdate();
                } else {
                    forceUpdateDebounced();
                }
            };

            for (let i = 0; i < nodes.length; i++) {
                let node = nodes[i];

                const getNodeSoftwareMessage = DMSMessageFactory.newMessage<void>(DMSMethod.GET_NODE_SOFTWARE, undefined, node.uid);
                const promise = this.props.dmsClient.sendMessage<IDMSResult<IDMSSoftwareBundle[]>>(getNodeSoftwareMessage, true);

                promise.then((res) => {
                    batchSoftwareQueryHandler(node.uid, res?.result, false);
                }).catch((err) => {
                    batchSoftwareQueryHandler(node.uid, undefined, true);
                });

                getSoftwarePromises.push(promise);
            }

            try {
                await Promise.allSettled(getSoftwarePromises);
            } catch (error) {

            }

            await Util.sleep(500);

            const keys = Object.keys(this.state.nodeManagingSession!.batchUpdateContext!);

            for (let i = 0; i < keys.length; i++) {
                let k = keys[i];

                const batchSoftwareQueryResults = this.state.nodeManagingSession!.batchUpdateContext![k];

                if (!batchSoftwareQueryResults.software || batchSoftwareQueryResults.offline) {
                    continue;
                }

                // iterate the available software from all nodes, keeping distinct values
                /*
                * e.g.
                * tgm1: tagomat, dms_client
                * sbd1: bagomat, dms_client
                * -  RESULT: dms_client
                * */

                for (let i1 = 0; i1 < batchSoftwareQueryResults.software.length; i1++) {
                    let sn = batchSoftwareQueryResults.software[i1];
                    const idx = availableSoftware.findIndex(s => s.name === sn.name && s.updateStrategy === sn.updateStrategy);

                    if (idx < 0) {
                        availableSoftware.push(sn);
                    }
                }
            }

            const softwareToExclude = new Array<string>();

            availableSoftware.forEach(_as => {
                //check if _as is available on ALL nodes

                for (let i = 0; i < keys.length; i++) {
                    let k = keys[i];

                    const batchSoftwareQueryResults = this.state.nodeManagingSession!.batchUpdateContext![k];

                    if (!batchSoftwareQueryResults.software || batchSoftwareQueryResults.offline) {
                        continue;
                    }

                    const idx = batchSoftwareQueryResults.software.findIndex(sn => sn.name === _as.name);

                    if (idx < 0) {
                        softwareToExclude.push(_as.name);
                    }
                }
            });

            availableSoftware = availableSoftware.filter((s) => {
                return softwareToExclude.indexOf(s.name) < 0;
            });

            this.setState(prevState => ({
                nodeManagingSession: {
                    ...prevState.nodeManagingSession!,
                    nodeSoftware: availableSoftware,
                    state: DMSNodeManagingSessionState.GOT_NODE_SOFTWARE
                }
            }));
        } catch (err) {
            this.displaySnackbar(`Could not get the installed software: ${JSON.stringify(err)}`, "error");
            this.setState({nodeManagingSession: undefined});
        }
    };

    private onDisconnectNodeRequested = async (node: IDBNode) => {
        try {
            const disconnectNodeMessage = DMSMessageFactory.newMessage(DMSMethod.DISCONNECT_NODE, {nodeId: node.uid});
            await this.props.dmsClient.sendMessage<IDMSResult<boolean>>(disconnectNodeMessage, true);

            this.displaySnackbar(`Disconnected node ${node.uid}`, "info");
        } catch (e) {
            this.displaySnackbar(`Could not disconnect node ${node.uid}: ${JSON.stringify(e)}`, "error");
        }
    };

    private onManageNodeRequested = async (node: IDBNode, mode: "software" | "config" | "restart" | "logs" | "actions") => {
        const connectedNode = this.props.dmsOnlineNodes[node.uid];

        if (!connectedNode) {
            this.displaySnackbar("Cannot proceed with the update, please upload a file first", "error");
            return;
        }

        switch (mode) {
            case "software":
                if (!this.props.dmsFiles || this.props.dmsFiles.length < 1) {
                    this.displaySnackbar("Update failed, please upload a file first", "error");
                    return;
                }
                break;
            case "config":
                break;
            case "restart":
                break;
            case "logs":
                break;
            case "actions":
                break;
            default:
                throw new Error(`Unsupported mode ${mode}`);
        }

        const nodeManagingSession: IDMSNodeManagingSession = {
            sessionMode: mode,
            targetNode: node,
            state: DMSNodeManagingSessionState.GETTING_NODE_SOFTWARE,
            batchUpdateProgress: 0
        };

        this.setState({
            nodeManagingSession
        });

        try {
            const getNodeSoftwareMessage = DMSMessageFactory.newMessage<void>(DMSMethod.GET_NODE_SOFTWARE, undefined, nodeManagingSession.targetNode!.uid);
            const result = await this.props.dmsClient.sendMessage<IDMSResult<IDMSSoftwareBundle[]>>(getNodeSoftwareMessage, true);

            if (mode === "actions" && result?.result) {
                result.result = result.result.filter(r => r.manifest !== undefined);

                const nodeDmsClient = result.result.find(s => s.name === "dms_client");

                if (nodeDmsClient && Util.compareVersions({
                    major: 2,
                    minor: 1,
                    build: 1
                }, nodeDmsClient!.manifest!.version) > 0) {
                    this.displaySnackbar(`Executing actions requires dms_client version 2.1.1 and up`, "error");
                    this.setState({
                        nodeManagingSession: undefined
                    });

                    return;
                }
            }

            this.setState(prevState => ({
                nodeManagingSession: {
                    ...nodeManagingSession,
                    state: DMSNodeManagingSessionState.SELECTING_SOFTWARE_TO_UPDATE,
                    nodeSoftware: result?.result
                }
            }));
        } catch (err) {
            this.displaySnackbar(`Could not get the installed software: ${JSON.stringify(err)}`, "error");
            this.setState({nodeManagingSession: undefined});
        }
    };

    private displaySnackbar = (message: string, variant: VariantType = "info") => {
        this.props.enqueueSnackbar(message, {
            variant: variant,
            anchorOrigin: {vertical: "top", horizontal: "center"}
        });
    };

    private renderOnlineColumn = (rowData) => {
        const {dmsOnlineNodes, bannedNodes, mode} = this.props;

        const isNodeOnline = dmsOnlineNodes && dmsOnlineNodes[rowData.uid] !== undefined;
        const isNodeBanned = bannedNodes && bannedNodes.findIndex(w => w.uid === rowData.uid) > -1;

        return isNodeBanned ? <IconButton onClick={() => {
            this.displaySnackbar(`Node '${rowData.uid}' is banned, you can unban it in the settings.`, "warning");
        }
        }> <VpnLockIcon/></IconButton> : <div><Fade
            style={{display: isNodeOnline ? "block" : "none"}}
            in={isNodeOnline}>
            <SignalCellularAltTwoToneIcon onClick={() => {
                if (mode === "nodes") {
                    this.displaySnackbar(`Capturing the screen of '${rowData.uid}@${rowData.location}'`);
                    this.captureScreenshot({targetNode: rowData});
                }
            }} aria-label={"online"} htmlColor="rgb(0,225,0)"/></Fade>
            <Fade
                style={{display: isNodeOnline ? "none" : "block"}}
                in={!isNodeOnline}>
                <SignalCellularConnectedNoInternet0BarTwoToneIcon aria-label={"offline"}
                                                                  htmlColor={"orange"}/></Fade>
        </div>;
    };

    private renderClientStateColumn = (rowData) => {
        const {dmsNodes, dmsOnlineNodes} = this.props;

        const isNodeOnline = dmsOnlineNodes && dmsOnlineNodes[rowData.uid] !== undefined;

        const nodeCurrentInstance = dmsNodes[rowData.uid] as IDBNode;

        if (!nodeCurrentInstance) {
            return;
        }

        const lastLogEntry = nodeCurrentInstance.lastLogEntry;

        const nodeStateIcon = DMSNodesView.getNodeStateIcon(lastLogEntry?.state);

        return <Chip
            variant={"outlined"}
            size={"small"}
            onClick={(e) => {
                e.preventDefault();
                this.onShowNodeInfoRequested(nodeCurrentInstance);
            }}
            icon={nodeStateIcon}
            style={isNodeOnline || !dmsOnlineNodes ?
                {
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    width: "200px",
                    maxWidth: "180px"
                } :
                {
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    width: "200px",
                    maxWidth: "180px",
                    color: "white",
                    backgroundColor: "rgba(119, 97, 64, 0.85)"
                }
            }
            avatar={nodeCurrentInstance && nodeCurrentInstance["updateProgress"] ?
                <CircularProgress
                    variant={nodeCurrentInstance["updateProgress"] === 100 || nodeCurrentInstance["updateProgress"] === 0 ? "indeterminate" : "determinate"}
                    value={nodeCurrentInstance["updateProgress"]}
                    size={12}/> : undefined}
            label={lastLogEntry?.state ?? "N/A"}/>;
    };

    private renderSoftwareColumn = (rowData) => {
        const {dmsNodes, dmsOnlineNodes} = this.props;
        const nodeCurrentInstance = dmsNodes[rowData.uid] ?? rowData;

        const isNodeOnline = dmsOnlineNodes && dmsOnlineNodes[nodeCurrentInstance.uid] !== undefined;

        return (nodeCurrentInstance.installedSoftware ?? []).filter(s => {
            return this.props.definedSoftware.findIndex(x => {
                return x.name === s.name;
            }) > -1;
        }).map((s, i) => {
            let nodeVersionString = s.version.major + "." + s.version.minor + "." + s.version.build;
            return <Chip
                onClick={!isNodeOnline ? undefined : (event) => {
                    event.preventDefault();

                    this.displaySnackbar(`Getting config for '${s.name}' on '${nodeCurrentInstance.uid}@${nodeCurrentInstance.location}'`, "info");

                    this.onShowNodeInfoRequested(nodeCurrentInstance, 4, s.name);
                }}
                icon={this.getSoftwareicon(s.name)}
                key={i}
                size={"small"}
                label={`${s.name} (${nodeVersionString})`}/>;
        });
    };

    private renderClientColumn = (rowData) => {
        const {dmsNodes} = this.props;
        const nodeCurrentInstance = dmsNodes[rowData.uid] ?? rowData;

        let nodeVersionString = "";
        let nodeIsOutdated = false;

        if (nodeCurrentInstance.clientVersion) {
            const {clientVersion} = nodeCurrentInstance;
            nodeVersionString = clientVersion.major + "." + clientVersion.minor + "." + clientVersion.build;

            if (this.props.globalSettings?.preferredClientVersions) {
                const preferredVersion = this.props.globalSettings.preferredClientVersions[nodeCurrentInstance.clientName];

                if (preferredVersion) {
                    nodeIsOutdated = Util.compareVersions(clientVersion, preferredVersion) < 0;
                }
            }
        }

        return (<Chip
            style={{
                backgroundColor: nodeIsOutdated ? "rgba(119, 97, 64, 0.85)" : undefined
            }} size={"small"}
            icon={nodeIsOutdated || !rowData.clientName ? <ErrorOutlineIcon/> : <StarIcon/>}
            label={`${rowData.clientName?.length > 0 ? rowData.clientName : "No Client"} ${nodeVersionString ? `(${nodeVersionString})` : ""}`}/>);
    };

    private getTwoFactorTokenPromise = (description?: string) => {
        return new Promise<string>((resolve, reject) => {
            this.props.twoFactorHandler({
                onGotToken: token => resolve(token),
                onCancelled: () => reject(),
                description
            });
        });
    };

    // TODO - re-organize

    private onCreateApiClientRequested = async (newApiClientRequest: INewApiClientRequest) => {
        if (!newApiClientRequest?.validates) {
            return;
        }

        const jwtToken = LocalStorageHelper.getAuthToken();

        if (jwtToken === null) {
            console.log("missing jwt token");
            return;
        }

        this.setState({isCreatingApiClientInProgress: true});

        try {
            const memberOf = newApiClientRequest.memberOf ?? [];

            if (newApiClientRequest.isRoot) {
                memberOf.push("root");
            }

            let twoFactorToken = "";

            try {
                twoFactorToken = await this.getTwoFactorTokenPromise("Creating an API client requires two-factor verification");
            } catch (e) {
                this.setState({
                    isCreatingApiClientInProgress: false
                });
                return;
            }

            this.props.cancelTwoFactorRequest();

            const generatedJwtToken = await this.props.dmsRestClient.registerApiClient(jwtToken, twoFactorToken, newApiClientRequest.username!, newApiClientRequest.password!, memberOf, newApiClientRequest.apiType!);
            await this.props.onLoadDataRequested();

            this.setState({isCreatingApiClientInProgress: false, newApiClientRequest: undefined, generatedJwtToken});

            this.displaySnackbar(`Registered API Client ${newApiClientRequest.username}`, "info");
        } catch (error: any) {
            let errorText: string;

            if (error.response) {
                errorText = `${error.response.status}:${error.response.statusText}`;
            } else {
                errorText = error.toString();
            }

            this.props.cancelTwoFactorRequest();

            this.setState({isCreatingApiClientInProgress: false, newApiClientRequest: undefined});
            this.displaySnackbar(`Could not register user: ${newApiClientRequest.username}: ${errorText}`, "error");
        }
    };

    private onDeleteApiClientRequested = async (apiClient: IDBNode) => {
        const jwtToken = LocalStorageHelper.getAuthToken();

        if (jwtToken === null) {
            console.log("missing jwt token");
            return;
        }

        this.setState({isDeletingApiClientInProgress: true});

        try {
            let twoFactorToken = "";

            try {
                twoFactorToken = await this.getTwoFactorTokenPromise("Deleting API Client '" + apiClient.uid + "' requires two-factor verification");
            } catch (e) {
                this.setState({
                    isDeletingApiClientInProgress: false
                });

                return;
            }

            this.props.cancelTwoFactorRequest();

            await this.props.dmsRestClient.deleteUser(jwtToken, twoFactorToken, apiClient.uid);

            await this.props.onLoadDataRequested();

            this.setState({isDeletingApiClientInProgress: false, deletionTargetApiClient: undefined});
            this.displaySnackbar(`Deleted API Client ${apiClient.uid}`, "info");
        } catch (error) {
            this.setState({isDeletingApiClientInProgress: false, deletionTargetApiClient: undefined});
            this.displaySnackbar(`Could not delete API Client: ${apiClient.uid}: ${error}`, "error");
        }
    };

    private renderGeneratedJwtTokenDialog = () => {
        const {generatedJwtToken} = this.state;

        return <ModalDialog
            open={generatedJwtToken !== undefined}
            title={"API Token"}
            message={`⚠️ Please make sure that you store and transfer this token securely, it contains sensitive information and will only be shown once, the API Client will have to be recreated if this configuration is lost!`}
            hideOkButton={true}
            buttonCancelTitle={"I have saved the token"}
            disableEscapeKeyDown={true}
            onCancel={() => {
                this.setState({
                    generatedJwtToken: undefined
                });
            }}>
            <div style={{paddingTop: 16}}>
                <TextField
                    value={generatedJwtToken}
                    multiline
                    maxRows={50}
                    fullWidth
                    InputProps={{
                        readOnly: true,
                    }}
                    label={"Generated token"}/>
            </div>
        </ModalDialog>;
    };

    private renderNewApiClientDialog = () => {
        const {classes} = this.props;

        const {newApiClientRequest, isCreatingApiClientInProgress} = this.state;

        return (<ModalDialog open={newApiClientRequest !== undefined}
                             buttonOkIsLoading={isCreatingApiClientInProgress}
                             buttonOkDisabled={!newApiClientRequest?.validates || isCreatingApiClientInProgress}
                             buttonCancelDisabled={isCreatingApiClientInProgress}
                             title={`Register a new API Client`}
                             buttonOkTitle={"Register"}
                             message={""}
                             onOk={() => {
                                 if (newApiClientRequest) {
                                     this.onCreateApiClientRequested(newApiClientRequest);
                                 }
                             }} onCancel={() => {
            this.setState({newApiClientRequest: undefined});
        }}>
            <Container>
                <TextField
                    variant={"standard"}
                    value={newApiClientRequest?.username ?? ""}
                    disabled={isCreatingApiClientInProgress}
                    onChange={(event) => {
                        let newValue = "";
                        if (event && event.target && event.target.value) {
                            newValue = event.target.value;
                        }

                        this.setState(prevState => ({
                            newApiClientRequest: {
                                ...prevState.newApiClientRequest,
                                username: newValue
                            }
                        }), () => {
                            this.validateNewApiClientRequest(this.state.newApiClientRequest);
                        });
                    }}
                    className={classes.dialogTextField}
                    fullWidth
                    label={"E-mail"}/>

                <FormControl style={{minWidth: "120px"}} fullWidth={true}>
                    <InputLabel>API Type</InputLabel>
                    <Select
                        value={newApiClientRequest?.apiType}
                        onChange={(event) => {
                            let newValue = "";
                            if (event && event.target && event.target.value) {
                                newValue = event.target.value as string;
                            }

                            if (newValue) {
                                this.setState(prevState => ({
                                    newApiClientRequest: {
                                        ...prevState.newApiClientRequest,
                                        apiType: newValue as any
                                    }
                                }), () => this.validateNewApiClientRequest(newApiClientRequest));
                            }
                        }}
                    >
                        {
                            ["bbc_forwarder", "generic_api"].map((k, key) => {
                                return <MenuItem key={key} value={k}>{`${k}`}</MenuItem>;
                            })
                        }
                    </Select>
                </FormControl>

                {
                    <div>
                        <TextField
                            variant={"standard"}
                            value={this.state.newApiClientRequest?.password ?? ""}
                            disabled={isCreatingApiClientInProgress}
                            onChange={(event) => {
                                let newValue = "";
                                if (event && event.target && event.target.value) {
                                    newValue = event.target.value;
                                }

                                this.setState(prevState => ({
                                    newApiClientRequest: {
                                        ...prevState.newApiClientRequest,
                                        password: newValue
                                    }
                                }), () => {
                                    this.validateNewApiClientRequest(this.state.newApiClientRequest);
                                });
                            }}
                            className={classes.dialogTextField}
                            fullWidth
                            autoComplete="new-password"
                            type={"password"}
                            label={"Password"}/>
                        <TextField
                            variant={"standard"}
                            value={this.state.newApiClientRequest?.passwordConfirmation ?? ""}
                            disabled={isCreatingApiClientInProgress}
                            onChange={(event) => {
                                let newValue = "";
                                if (event && event.target && event.target.value) {
                                    newValue = event.target.value;
                                }

                                this.setState(prevState => ({
                                    newApiClientRequest: {
                                        ...prevState.newApiClientRequest,
                                        passwordConfirmation: newValue
                                    }
                                }), () => {
                                    this.validateNewApiClientRequest(this.state.newApiClientRequest);
                                });
                            }}
                            className={classes.dialogTextField}
                            fullWidth
                            autoComplete="new-password"
                            type={"password"}
                            error={(newApiClientRequest?.passwordWeakness ?? 1) < 1}
                            helperText={(newApiClientRequest?.passwordWeakness ?? 1) < 1 ? "Weak password" : undefined}
                            label={"Confirm Password"}/>
                    </div>
                }

                <Autocomplete
                    multiple
                    options={(this.props.dmsUserGroups ?? []).map(value => value.id)}
                    disabled={isCreatingApiClientInProgress}
                    freeSolo
                    renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                            <Chip variant="outlined" label={option} {...getTagProps({index})} />
                        ))
                    }
                    onChange={(event, value) => {
                        this.setState(prevState => ({
                            newApiClientRequest: {
                                ...prevState.newApiClientRequest,
                                memberOf: value
                            }
                        }), () => {
                            this.validateNewApiClientRequest(this.state.newApiClientRequest);
                        });
                    }}
                    renderInput={(params) => (
                        <TextField
                            variant={"standard"}
                            {...params}
                            label="Member Of"
                            placeholder="Member Of"
                        />
                    )}
                />

                <FormControlLabel
                    style={{marginTop: "8px"}}
                    control={
                        <Checkbox
                            disabled={isCreatingApiClientInProgress || newApiClientRequest?.isViewer}
                            onChange={(e) => {
                                const checked = e.target?.checked ?? false;
                                this.setState(prevState => ({
                                    newApiClientRequest: {
                                        ...prevState.newApiClientRequest,
                                        isRoot: checked,
                                    }
                                }));
                            }
                            }
                            checked={newApiClientRequest?.isRoot}
                            color="primary"
                        />
                    }
                    label="Has root access"
                />

            </Container>
        </ModalDialog>);
    };

    private renderDeleteApiClientDialog = () => {
        const {isLoadingNodes} = this.props;

        return (<ModalDialog open={this.state.deletionTargetApiClient !== undefined}
                             buttonOkDisabled={this.state.isDeletingApiClientInProgress}
                             buttonCancelDisabled={this.state.isDeletingApiClientInProgress}
                             buttonOkIsLoading={this.state.isDeletingApiClientInProgress}
                             title={`Are you sure you want to delete '${this.state?.deletionTargetApiClient?.uid}'`}
                             onOk={() => {
                                 this.onDeleteApiClientRequested(this.state.deletionTargetApiClient!);
                             }} onCancel={() => {
            this.setState({deletionTargetApiClient: undefined});
        }}>
            {"This action is permanent and cannot be undone!"}
            <LinearProgress
                style={{transition: "all ease-in-out 0ms", opacity: isLoadingNodes ? 1 : 0, marginTop: 8}}
            />
        </ModalDialog>);
    };

    private validateNewApiClientRequest = Util.debounce((newApiClientRequest: INewApiClientRequest) => {
        let isValid: boolean;
        let passwordWeakness: number | undefined;

        const req = newApiClientRequest;

        if (!req) {
            isValid = false;
        } else {
            if (req.viewerToken) {
                isValid = ValidationHelper.validateUser(req.username, req.password, undefined, req.viewerToken);
            } else {
                const v = ValidationHelper.validateUser(req.username, req.password);

                isValid = req.password === req.passwordConfirmation && v;

                const pwValidationResult = ValidationHelper.validatePassword(req.password!);

                if (isValid) {
                    isValid = pwValidationResult.valid;
                }
                passwordWeakness = pwValidationResult.weakness;
            }
        }

        this.setState(prevState => ({
            newApiClientRequest: {
                ...prevState.newApiClientRequest,
                validates: isValid,
                passwordWeakness
            }
        }));
    }, 500);
}


//export default withStyles(styles, {withTheme: true})(DMSNodesView);