import {IDMSDataRendererSection} from "./IDMSDataRendererSection";
import {IDMSDataRendererItem} from "./IDMSDataRendererItem";
import IDataRendererMapping from "./IDataRendererMapping";
import IDataRendererMappingItem from "./IDataRendererMappingItem";
import exp from "node:constants";
import IDataRendererMappingSection from "./IDataRendererMappingSection";

class DMSDataRendererConverter {
    public static convertConfig = (mapping: IDataRendererMapping, configContents: string): IDMSDataRendererSection[] => {
        let dataSections: IDMSDataRendererSection[] = [];

        switch (mapping.converter) {
            case "config.properties":
                dataSections = this.convertConfigProperties(mapping, configContents);
                break;
            case "json":
                dataSections = this.convertConfigJson(mapping, configContents);
                break;
        }

        // Update the items' newValue property
        for (const section of dataSections) {
            for (const item of section.items) {
                item.newValue = item.originalValue;
            }
        }

        console.log(dataSections);

        return dataSections;
    };

    private static convertConfigProperties = (mapping: IDataRendererMapping, configContents: string) => {
        const dataSections: IDMSDataRendererSection[] = [];

        // process each mapping section, one by one

        for (const mappingSection of mapping.sections) {
            try {
                const dataSection: IDMSDataRendererSection = {
                    title: mappingSection.title,
                    maxColumns: mappingSection.metadata.maxColumns ?? 1,
                    items: []
                };

                for (const mappingItem of mappingSection.items) {
                    const lines = configContents.split("\n");

                    const idx = lines.findIndex(line => line.startsWith(mappingItem.field));

                    if (idx < 0) {
                        continue;
                    }

                    const configValues = lines[idx].split("=")[1];

                    dataSection.rawValue = configValues;

                    const separator = mappingItem.metadata.separator ?? ",";

                    const splitValueItems = configValues.split(separator).filter(i => {
                        return /\S/.test(i);
                    });

                    for (let item of splitValueItems) {
                        // parse config value, convert to IDMSDataRendererItem
                        item = item.trim();

                        const title = mappingItem.title ?? DMSDataRendererConverter.getTitle(mapping, mappingItem, item);

                        const processedValue = DMSDataRendererConverter.getValue(mapping, mappingItem, item);

                        if (!title || !processedValue) {
                            // skip items without a title, or a without a value
                            continue;
                        }

                        dataSection.items.push({
                            id: item,
                            title,
                            originalValue: processedValue,
                            metadata: {
                                type: mappingItem.metadata.type,
                                readonly: mappingItem.metadata.readonly
                            }
                        });
                    }
                }

                if (dataSection.items.length > 0) {
                    dataSections.push(dataSection);
                }
            } catch (e) {
                console.warn(e, `failed to convert ${mapping.converter} config`);
            }
        }

        return dataSections;
    };

    private static convertConfigJson = (mapping: IDataRendererMapping, configContents: string) => {
        const dataSections: IDMSDataRendererSection[] = [];

        const obj = JSON.parse(configContents);

        // process each mapping section, one by one

        for (const mappingSection of mapping.sections) {
            try {
                const dataSection: IDMSDataRendererSection = {
                    maxColumns: mappingSection.metadata.maxColumns,
                    items: [],
                    title: mappingSection.title
                };

                for (const mappingItem of mappingSection.items) {
                    // get the field value
                    const pathToField = mappingItem.field.split(".");

                    let value = obj;

                    // if the object uses dot syntax, e.g. Person.Name.FirstName, then expand the object, by going down the tree

                    for (const p of pathToField) {
                        if (!value) {
                            continue;
                        }

                        value = value[p];
                    }

                    if (!value) {
                        continue;
                    }

                    // if it's not an array, convert it to a single-element array
                    if (!Array.isArray(value)) {
                        value = new Array(value);
                    }

                    for (const itemValue of (value as any[])) {
                        // convert each and every item to a data renderer item
                        const title = mappingItem.title ?? this.getTitle(mapping, mappingItem, itemValue);
                        const processedValue = this.getValue(mapping, mappingItem, itemValue);

                        if (title === undefined || processedValue === undefined) {
                            // skip items without a title, or a without a value
                            continue;
                        }

                        dataSection.items.push({
                            title,
                            originalValue: processedValue,
                            id: mappingItem.field,
                            metadata: {
                                type: mappingItem.metadata.type,
                                readonly: mappingItem.metadata.readonly
                            }
                        });
                    }
                }

                if (dataSection.items.length > 0) {
                    dataSections.push(dataSection);
                }

            } catch (e) {
                console.warn(e, `failed to convert ${mapping.converter} config`);
            }
        }

        return dataSections;
    };

    private static getTitle = (mapping: IDataRendererMapping, mappingItem: IDataRendererMappingItem, value: string): string | undefined => {
        switch (mapping.converter) {
            case "config.properties":
                return DMSDataRendererConverter.getTitleConfigProperties(mappingItem, value);
            case "json":
                return DMSDataRendererConverter.getTitleJsonObject(mappingItem, value);
            default:
                return value;
        }
    };

    private static getValue = (mapping: IDataRendererMapping, mappingItem: IDataRendererMappingItem, value: string): string | undefined => {
        switch (mapping.converter) {
            case "config.properties":
                return DMSDataRendererConverter.getValueConfigProperties(mapping, mappingItem, value);
            case "json":
                return DMSDataRendererConverter.getValueJsonObject(mapping, mappingItem, value);
            default:
                return value;
        }
    };

    private static getTitleConfigProperties = (mappingItem: IDataRendererMappingItem, value: string): string | undefined => {
        switch (mappingItem.metadata.type) {
            case "language":
                try {
                    const displayNames = new Intl.DisplayNames(['en'], {type: 'language'});
                    return displayNames.of(value) ?? value;
                } catch (e) {
                    console.warn(e, "unknown language: " + value);
                    return value;
                }
            case "airline_logo":
                switch (value) {
                    case "airlineCloudSas":
                    case "airlineSasPieceConcept":
                        return "SAS";
                    case "airlineBagsSas":
                        return "SAS (Bags)";
                    case "airlineBagsWideroe":
                    case "airlineBags2Wideroe":
                        return "Widerøe (Bags)";
                    case "airlineCloudKLM":
                    case "airlineKLM":
                        return "KLM";
                    case "airlineBagsKLM":
                        return "KLM (Bags)";
                    case "airlineBagsFlyr":
                        return "Flyr (Bags)";
                    case "airlineCloudWizzairWgh":
                        return "Wizz Air (WGH)";
                    case "airlineBagsWizzAir":
                        return "Wizz Air (Bags)";
                    case "airlineBagsWizzAirUk":
                        return "Wizz Air (Bags, UK)";
                    case "airlineBagsLufttransport":
                        return "Lufttransport (Bags)"; //L5
                    case "airlineBagsJettime":
                        return "Jettime"; //JP;
                    case "airlineCloudThomasCookScandinavia":
                        return "Thomas Cook (Scandinavia)";
                    case "airlineBagsThomasCook":
                        return "Thomas Cook (Bags)";
                    case "airlineCloudNorwegian":
                    case "airlineNorwegian":
                        return "Norwegian";
                    case "airlineBagsNorwegian":
                        return "Norwegian (Bags)";
                    case "airlineCloudNorwegianIntrnational":
                        return "Norwegian (intl)";
                    case "airlineCloudRyainairWgh":
                        return "Ryanair (WGH)";
                    case "airlineCloudRyainairUkWgh":
                        return "Ryanair (UK WGH)";
                    case "airlineCloudAirBalticWgh":
                        return "AirBaltic";
                    case "airlineCloudWgh":
                        return "WGH";
                    case "airlineBagsWgh":
                        return "WGH (Bags)";
                    case "airlineCloudBraWgh":
                    case "airlineBagsBra":
                        return "WGH Bags (BRA)";
                    case "airlineCloudWizzair":
                        return "Wizz Air";
                    case "airlineCloudWizzairUk":
                        return "Wizz Air (UK)";
                    case "airlineCloudWizzairMalta":
                        return "Wizz Air (Malta)";
                    case "airlineCloudAegean":
                    case "airlineAegean":
                        return "Aegean";
                    case "airlineCloudAegeanWgh":
                        return "Aegean (WGH)";
                    case "airlineBagsAegean":
                        return "Aegean (Bags)";
                    case "airlineAirFrance":
                    case "airlineCloudAirFrance":
                        return "Air France";
                    case "airlineBagsAirFrance":
                        return "Air France (Bags)";
                    case "airlineCloudLufthansa":
                        return "Lufthansa";
                    case "airlineBagsLufthansa":
                        return "Lufthansa (Bags)";
                    case "airlineCloudAustrian":
                        return "Austrian";
                    case "airlineBagsAustrian":
                        return "Austrian (Bags)";
                    case "airlineCloudSwiss":
                        return "Swiss";
                    case "airlineBagsSwiss":
                        return "Swiss (Bags)";
                    case "airlineCloudBrussels":
                        return "Brussels";
                    case "airlineCloudEgyptair":
                        return "Egyptair";
                    case "airlineCloudEmirates":
                        return "Emirates";
                    case "airlineCloudFinnair":
                        return "Finnair";
                    case "airlineBagsFinnair":
                        return "Finnair (Bags)";
                    case "airlineAtlantic":
                    case "operatorAtlanticNoTag":
                        return "Atlantic";
                    case "airlineBagsAtlanticAirways":
                        return "Atlantic (Bags)";
                    case "airlineBagsTuiFreeBird":
                        return "Freebird Airlines (Bags)";
                    case "airlineBagsTuiFlyNordic":
                        return "TUI fly Nordic (Bags)";
                    case "airlineBagsTuiBHAir":
                        return "TUI BH (Bags)";
                    case "airlineBagsEdelweiss":
                        return "Edelweiss Airlines (Bags)";
                    case "airlineBagsIcelandair":
                        return "Icelandair (Bags)";
                    case "airlineCloudIcelandair":
                        return "Icelandair";
                    case "airlineBagsIberia":
                        return "Iberia (Bags)";
                    case "airlineBagsPrivateWings":
                        return "Wings Private (Bags)";
                    case "airlineBagsTransaviaFrance":
                        return "Transavia France (Bags)";
                    case "airlineBagsTransavia":
                        return "Transavia (Bags)";
                    case "airlineBagsDat":
                        return "DAT (Bags)";
                    case "airlineCloudOlympic":
                        return "Olympic";
                    case "airlineCloudKoreanair":
                        return "Korean Air";
                    case "airlineBagsCondor":
                        return "Condor (Bags)";
                    case "airlineUnknown":
                    case "airlineBagsUnknownTagPrefix":
                    case "operator62nNoTag":
                        return undefined;
                    case "operatorFaeNoTag":
                        return undefined;
                    default:
                        alert("unknown airline: " + value);
                        return value;
                }
            default:
                return value;
        }
    };

    private static getTitleJsonObject = (mappingItem: IDataRendererMappingItem, value: string): string | undefined => {
        switch (mappingItem.metadata.type) {
            case "language":
                return DMSDataRendererConverter.getTitleConfigProperties(mappingItem, value);
            case "airline_logo":
                switch (value) {
                    case "DY":
                        return "Norwegian";
                    case "SK":
                        return "SAS";
                    case "D8":
                        return "Norwegian Air Sweden";
                    case "KL":
                        return "KLM";
                    case "AF":
                        return "Air France";
                    case "RC":
                        return "Atlantic";
                    case "WF":
                        return "Widerøe";
                    case "W6":
                        return "Wizz Air";
                    case "DK":
                        return "Sunclass Airlines";
                    case "LH":
                        return "Lufthansa";
                    case "OS":
                        return "Austrian";
                    case "LX":
                        return "Swiss";
                    case "RC":
                        return "Atlantic";
                    case "A3":
                        return "Aegean";
                    case "BLX":
                        return "TUI fly Nordic";
                    case "FI":
                        return "Icelandair";
                    case "AY":
                        return "Finnair";
                    case "SN":
                        return "Brussels Airlines";
                    case "WF_WGH":
                        return "Widerøe (WGH)";
                }
                break;
            default:
                return value;
        }
    };

    private static getValueConfigProperties = (mapping: IDataRendererMapping, mappingItem: IDataRendererMappingItem, value: string): string | undefined => {
        switch (mappingItem.metadata.type) {
            case "airline_logo":
                switch (value) {
                    case "airlineCloudSas":
                    case "airlineSasPieceConcept":
                    case "airlineBagsSas":
                        return "SK";
                    case "airlineBags2Wideroe":
                    case "airlineBagsWideroe":
                        return "WF";
                    case "airlineCloudKLM":
                    case "airlineBagsKLM":
                    case "airlineKLM":
                        return "KL";
                    case "airlineCloudWizzairWgh":
                    case "airlineBagsWizzAir":
                    case "airlineBagsWizzAirUk":
                        return "W4";
                    case "airlineCloudThomasCookScandinavia":
                    case "airlineBagsThomasCook":
                        return "TOM";
                    case "airlineCloudNorwegian":
                    case "airlineBagsNorwegian":
                    case "airlineNorwegian":
                        return "DY";
                    case "airlineCloudNorwegianIntrnational":
                        return "D8";
                    case "airlineCloudRyainairWgh":
                        return "RK";
                    case "airlineCloudRyainairUkWgh":
                        return "RK";
                    case "airlineCloudAirBalticWgh":
                        return "BT";
                    case "airlineCloudWgh":
                        return "WF";
                    case "airlineCloudBraWgh":
                    case "airlineBagsBra":
                    case "airlineBagsWgh":
                        return "WF";
                    case "airlineCloudWizzair":
                    case "airlineCloudWizzairUk":
                    case "airlineCloudWizzairMalta":
                        return "W4";
                    case "airlineCloudAegean":
                    case "airlineBagsAegean":
                    case "airlineCloudAegeanWgh":
                    case "airlineAegean":
                        return "A3";
                    case "airlineCloudAirFrance":
                    case "airlineBagsAirFrance":
                    case "airlineAirFrance":
                        return "AF";
                    case "airlineCloudLufthansa":
                    case "airlineBagsLufthansa":
                        return "LH";
                    case "airlineCloudAustrian":
                    case "airlineBagsAustrian":
                        return "OS";
                    case "airlineCloudSwiss":
                    case "airlineBagsSwiss":
                        return "LX";
                    case "airlineCloudBrussels":
                        return "SN";
                    case "airlineCloudEgyptair":
                        return "MS";
                    case "airlineCloudEmirates":
                        return "EK";
                    case "airlineCloudFinnair":
                    case "airlineBagsFinnair":
                        return "AY";
                    case "airlineAtlantic":
                    case "operatorAtlanticNoTag":
                    case "airlineBagsAtlanticAirways":
                        return "RC";
                    case "airlineBagsTuiFreeBird":
                        return "TB";
                    case "airlineBagsTuiFlyNordic":
                    case "airlineBagsTuiBHAir":
                        return "6B";
                    case "airlineBagsIcelandair":
                    case "airlineCloudIcelandair":
                        return "FI";
                    case "airlineBagsIberia":
                        return "IB";
                    case "airlineBagsTransaviaFrance":
                    case "airlineBagsTransavia":
                        return "TO";
                    case "airlineBagsEdelweiss":
                        return "WK";
                    case "airlineBagsDat":
                        return "DX";
                    case "airlineCloudOlympic":
                        return "OA";
                    case "airlineCloudKoreanair":
                        return "KE";
                    case "airlineBagsLufttransport":
                        return "L5";
                    case "airlineBagsJettime":
                        return "JP";
                    case "airlineBagsCondor":
                        return "DE";
                }
                break;
        }

        return value;
    };

    private static getValueJsonObject = (mapping: IDataRendererMapping, mappingItem: IDataRendererMappingItem, value: string): string | undefined => {
        switch (mappingItem.metadata.type) {
            default:
                return value;
        }
    };
}

export default DMSDataRendererConverter;